let stackShadow = '3px 3px rgba(210, 210, 210, 1)';
let draggingBackground = "rgba(235, 235, 235, 1)";
let combineIndicator = "#D8F2E0";
let linkColor = '#1976d2';

let makeListItemStyle = ({ snapshot, leaf, selectedTaskIds, combineTarget, isLink }) => {
    let style = {
        boxShadow: snapshot?.isDragging && selectedTaskIds?.length > 1 ? stackShadow : 'none',
        background:
            combineTarget && combineTarget === leaf._id
                ?
                combineIndicator // green if user is about to drop something into this leaf
                : leaf.background ??
                (snapshot?.isDragging ?
                    draggingBackground
                    : ''
                ),
        opacity: snapshot?.isDragging && (combineTarget || snapshot.draggingOver !== "leaf-list") ? 0.5 : 1
    };
    if (leaf.textColor) {
        style.color = leaf.textColor;
    } else if (isLink) {
        style.color = linkColor;
    }
    if (leaf.textAlign) {
        style.textAlign = leaf.textAlign;
    }
    if (leaf.margin) {
        style.margin = leaf.margin;
    }
    if(leaf.borderRadius)
        style.borderRadius = leaf.borderRadius;

    return style;
}

export { makeListItemStyle }