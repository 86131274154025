
import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from "react-router-dom";
// material UI
import { Box, Typography, TextField, Button } from '@mui/material';

const PasswordResetLander = () => {
    let { resetToken } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if (!resetToken) {
            setRedirect(true);
            alert('Invalid password reset link. Please request a new one.');
        }
    }, [])

    const handleInputChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        if (event.key !== 'Enter') {
            return;
        } else {
           resetPassword(resetToken);
        }
    };

    const resetPassword = (resetToken) => {
        if (invalidForm())
            return;
        setButtonDisabled(true);
        let body = {
            resetToken: resetToken,
            password: password
        };
        body = JSON.stringify(body);

        fetch('/api/reset-password', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: body
        })
            .then(response => {
                setRedirect(true);
                if (response.status === 200) {
                    alert('Password reset. Log in with your new password.')
                } else {
                    alert('Invalid password reset link. Please request a new one.')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    const invalidForm = () => {
        let invalidForm = false;
        if (!password || password.length < 10) {
            setPasswordError("Enter password with at least 10 characters");
            invalidForm = true;
        } else {
            setPasswordError(null);
        }
        return invalidForm;
    }

    return redirect ?
        <Redirect to='/home' />
        :
        <Box mt={2}>
            <Typography variant='h6'>
                🔐 Reset password
            </Typography>
            <Typography color="textSecondary">
                Enter a new password that's more than 10 characters
            </Typography>
            <Box mt={2}>
                <TextField
                    data-testid='password-input'
                    id="password-input"
                    value={password}
                    label={passwordError !== null ? passwordError : "New password"}
                    error={passwordError !== null}
                    type="password"
                    variant="filled"
                    fullWidth
                    onChange={handleInputChange}
                    onKeyPress={handleSubmit}
                    autoFocus
                />
            </Box>
            <Box mt={2}>
                <Button data-testid='pw-reset-button' variant="contained" disabled={buttonDisabled} color="primary" onClick={() => resetPassword(resetToken)}>Reset password</Button>
            </Box>
        </Box>
}

export default PasswordResetLander;