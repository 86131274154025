
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
// material UI
import { Box, Typography, TextField, Button } from '@mui/material';
// my functions
import { validEmail } from '../util/url-checker';

const PasswordResetRequest = () => {
    let { emailAddress } = useParams();
    const [email, setEmail] = useState(emailAddress ?? '');
    const [emailError, setEmailError] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        if (event.key !== 'Enter') {
            return;
        } else {
           requestPasswordReset(emailAddress);
        }
    };

    const requestPasswordReset = (emailAddress) => {
        if (invalidForm())
            return;
        setButtonDisabled(true);
        let body = {
            email: email
        };
        body = JSON.stringify(body);
        fetch('/api/request-reset-token', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: body
        })
            .then(response => {
                if (response.status === 200) {
                    alert('Reset link sent. Please check your email.')
                } else {
                    alert('Invalid email.')
                }
                setButtonDisabled(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setButtonDisabled(false);
            })
    }

    const invalidForm = () => {
        let invalidForm = false;
        if (!validEmail(email)) {
            setEmailError("Enter a valid email address.");
            invalidForm = true;
        } else {
            setEmailError(null);
        }
        return invalidForm;
    }

    return (
        <Box mt={2}>
            <Typography variant='h6'>
                🔐 Request password reset
            </Typography>
            <Typography color="textSecondary">
                Enter the email that you use with Ginkgo
            </Typography>
            <Box mt={2}>
                <TextField
                    data-testid='email-input'
                    id="email-input"
                    value={email}
                    label={emailError !== null ? emailError : "Email"}
                    error={emailError !== null}
                    type="email"
                    variant="filled"
                    fullWidth
                    onChange={handleInputChange}
                    onKeyPress={handleSubmit}
                    autoFocus
                />
            </Box>
            <Box mt={2}>
                <Button data-testid='pw-reset-button' variant="contained" disabled={buttonDisabled} color="primary" onClick={() => requestPasswordReset(email)}>Request reset link</Button>
            </Box>
        </Box>
    )
}

export default PasswordResetRequest;