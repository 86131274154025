// material UI
import {
    ListItem,
    ListItemText,
    Link
} from '@mui/material';
import { validImageUrl } from '../../util/url-checker';
// styles
import { makeStyle } from '../../styles/listItemText';
import { makeListItemStyle } from '../../styles/listItem';
// my components
import ListItemContent from './listItemContent';
import ContextMenuButton from './contextMenuButton';

export default function GinkgoLink({
    leaf, onClick, onContextMenu, handleLeafContextClick,
    combineTarget, currentLeaf,
    selectedTaskIds, dragInProgress, shared, provided, snapshot
}) {
    let hideContextMenu = shared && leaf.locked;

    return (
        <ListItem
            data-testid="list-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            button={leaf.locked && validImageUrl(leaf.content) ? false : true}
            disabled={leaf.disabled}
            component={leaf.locked && validImageUrl(leaf.content) && shared ? ListItem : Link}
            target={leaf.sameTab? "" : "_blank"}
            // rel="noopener noreferrer"
            href={leaf.linkTo ?? leaf.content}
            onClick={onClick}
            selected={selectedTaskIds ? selectedTaskIds.indexOf(leaf._id) !== -1 : false}
            onContextMenu={onContextMenu}
            style={{
                ...provided.draggableProps.style,
                ...makeListItemStyle({
                    snapshot: snapshot,
                    leaf: leaf,
                    combineTarget: combineTarget,
                    selectedTaskIds: selectedTaskIds,
                    isLink: true
                })
            }}
        >
            <ListItemText style={makeStyle({
                dragInProgress: dragInProgress,
                snapshot: snapshot,
                leaf: leaf,
                selectedTaskIds: selectedTaskIds,
                shared: shared,
                currentLeaf: currentLeaf
            })}>
                <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
            </ListItemText>
            { /* need this fragment to make react-beautiful-dnd work correctly */}
            {!hideContextMenu &&
                <ContextMenuButton
                    leaf={leaf}
                    onClick={handleLeafContextClick}
                    snapshot={snapshot}
                    selectedTaskIds={selectedTaskIds}
                />
            }
        </ListItem>
    )
}