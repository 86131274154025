
import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from "react-router-dom";
// material UI
import { List, Box } from '@mui/material';
// my components
import BreadcrumbNav from "./breadcrumbNav";
import LeafSettingsMenu from "./leafSettingsMenu";
import DraggableListItem from "./list-item/draggableListItem";
import TextInput from "./TextInput";

const LoginLinkLander = ({ setToken }) => {
    let { emailToken } = useParams();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (!emailToken) {
            alert('Invalid login link. Please try to login again.');
        } else {
            getToken(emailToken)
        }
    }, [])

    const getToken = (emailToken) => {
        let credentials = {
            emailToken: emailToken
        };
        credentials = JSON.stringify(credentials);
        fetch('/api/email-login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: credentials
        })
            .then(response => {
                setRedirect(true);
                if (response.status === 200) {
                    response.json()
                        .then(tokenResponse => {
                            let token = tokenResponse.accessToken;
                            setToken(token);
                            localStorage.setItem('token', token);
                        })
                } else {
                    alert('Invalid login link. Please try to login again.')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    return redirect ?
        <Redirect to='/home' />
        :
        <>
            <BreadcrumbNav />
            <LeafSettingsMenu />
            <Box mt={2}>
                <List>
                    <DraggableListItem />
                    <DraggableListItem />
                    <DraggableListItem />
                    <DraggableListItem />
                    <DraggableListItem />
                </List>
                <TextInput />
            </Box>
        </>
    // <>
    //     <br />
    //     <Typography variant='h6'>
    //         🌱 Welcome to Ginkgo!
    //     </Typography>
    //     <Typography color="textSecondary">
    //         Ginkgo is a space for organizing your thoughts and to-dos
    //     </Typography>
    //     <br />
    // </>
}

export default LoginLinkLander;