export { tomorrowMorning, tomorrowEvening }

function tomorrowMorning() {
    let today = new Date();
    let tomorrow = new Date(today);
    let hrs = today.getHours()
    // If you're up late, then what's perceived as tomorrow is likely the same date so don't add a day
    if(hrs > 4)
        tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(9, 0, 0, 0);
    return tomorrow.getTime();
}

function tomorrowEvening() {
    let today = new Date();
    let tomorrow = new Date(today);
    let hrs = today.getHours()
    if(hrs > 4)
        tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(17, 0, 0, 0);
    return tomorrow.getTime();
}