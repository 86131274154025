// third party libs
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
// material UI
import { List, Box } from "@mui/material";

// my components
import BreadcrumbNav from "./breadcrumbNav";
import ReadOnlyLeaf from "./readOnlyLeaf";
import EditableLeaf from "./editableLeaf";
import LeafSettingsMenu from "./leafSettingsMenu";
import DraggableListItem from "./list-item/draggableListItem";
import NotFound from "./404";
// util
import { updatePwaAssets, isPwaInstalled } from '../util/pwa-utils';
import InstallPrompt from './installPrompt';

const SharedLeaf = ({ leaves, setLeaves, setLinkshareId, currentLeaf, setCurrentLeaf,
    token, linkShareId, setDeepShared, combineTarget, secureShare
}) => {
    let { linkshareId, secureShareToken } = useParams();
    const [loading, setLoading] = useState(true);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    // get settings for current leaf
    useEffect(() => {
        if (!linkshareId) { 
            linkshareId = linkShareId;
        } else if (linkshareId !== null) { 
            setLinkshareId(linkshareId);
        }
        setDeepShared(false);
        // console.log("get current leaf called");
        setLoading(true);
        setCurrentLeaf(null);
        fetch('/api/share/leaves', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Link ' + linkshareId
            }
        })
            .then(response => response.json())
            .then(leaf => {
                document.title = leaf.currentLeaf.content;
                setLeaves(leaf.leaves);
                setCurrentLeaf(leaf.currentLeaf);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            })
    }, [linkshareId, setLeaves]);

    useEffect(() => {
        updatePwaAssets({
            faviconUrl: currentLeaf?.faviconUrl,
            appleTouchIconUrl: currentLeaf?.appleTouchIconUrl,
            appName: currentLeaf?.appName,
            appBackground: currentLeaf?.pageBackground
        })

        if (!isPwaInstalled() && currentLeaf?.showInstallPrompt)
            setShowInstallPrompt(true);

    }, [currentLeaf])

    let editableLeafPath = secureShare ? '/secure-share/' + secureShareToken + '/' : linkshareId + '/';
    let readOnlyLeafPath = secureShare ? '/secure-share/' + secureShareToken + '/' : '/share/' + linkshareId + '/';

    return (
        <>
            {!currentLeaf || !leaves ?
                <>
                    {loading ?
                        <>
                            <BreadcrumbNav />
                            <LeafSettingsMenu />
                            <Box mt={2}>
                                <List>
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                </List>
                            </Box>
                        </>
                        :
                        <NotFound />
                    }
                </>
                :
                <>
                    <InstallPrompt 
                        showInstallPrompt={showInstallPrompt}
                        setShowInstallPrompt={setShowInstallPrompt}
                        leaf={currentLeaf}
                    />
                    <BreadcrumbNav 
                        currentLeaf={currentLeaf} 
                        secureShare={secureShare} 
                        secureShareToken={secureShareToken}
                    />
                    {currentLeaf.editable ?
                        // editableLeaf needs to be different from leaf in that there's no combining, no leaf links, and fewer menu options
                        <EditableLeaf
                            currentLeaf={currentLeaf}
                            setCurrentLeaf={setCurrentLeaf}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            linkShareId={linkShareId}
                            token={token}
                            path={editableLeafPath}
                            deepShared={false}
                            renderLinks={currentLeaf.shareDeep}
                            combineTarget={combineTarget}
                        />
                        :
                        <ReadOnlyLeaf
                            linkShareId={linkShareId}
                            currentLeaf={currentLeaf}
                            setCurrentLeaf={setCurrentLeaf}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            path={readOnlyLeafPath}
                            renderLinks={currentLeaf.shareDeep}
                        />
                    }
                </>
            }
        </>
    )

}

export default SharedLeaf;