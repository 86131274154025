// material UI
import {
    ListItem,
    ListItemText
} from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
// styles
import { makeStyle } from '../../styles/listItemText';
import { makeListItemStyle } from '../../styles/listItem';
// my components
import ListItemContent from './listItemContent';
import ContextMenuButton from './contextMenuButton';

export default function GinkgoLink({
    leaf, onClick, onContextMenu, handleLeafContextClick,
    combineTarget, currentLeaf, renderLinks, path,
    selectedTaskIds, dragInProgress, shared, provided, snapshot
}) {

    let hideContextMenu = shared && leaf.locked;

    return (
        <ListItem
            data-testid="list-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            disabled={leaf.disabled || false}
            selected={selectedTaskIds && selectedTaskIds.indexOf(leaf._id) !== -1}
            button
            component={renderLinks ? NavLink : ListItem}
            onClick={onClick}
            to={path + leaf._id} // needs fix for deep shared leaf child links
            onContextMenu={onContextMenu}
            style={{
                ...provided.draggableProps.style,
                ...makeListItemStyle({
                    snapshot: snapshot,
                    leaf: leaf,
                    combineTarget: combineTarget,
                    selectedTaskIds: selectedTaskIds,
                    isLink: false
                })
            }}
        >
            <ListItemText style={makeStyle({
                dragInProgress: dragInProgress,
                snapshot: snapshot,
                leaf: leaf,
                selectedTaskIds: selectedTaskIds,
                shared: shared,
                currentLeaf: currentLeaf
            })} >
                <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
            </ListItemText>
            { /* need this fragment to make react-beautiful-dnd work correctly */}
            {!hideContextMenu &&
                <ContextMenuButton
                    leaf={leaf}
                    onClick={handleLeafContextClick}
                    snapshot={snapshot}
                    selectedTaskIds={selectedTaskIds}
                />
            }
        </ListItem>
    )
}