import { Draggable } from 'react-beautiful-dnd';
// material UI
import {
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { validUrl } from '../../util/url-checker';
// my components
import GinkgoLink from './ginkgoLink';
import GinkgoItem from './ginkgoItem';
import GinkgoChecklistItem from './ginkgoChecklistItem';
import GinkgoShareButton from './ginkgoShareButton';

const DraggableListItem = ({
    leaf, index, path, handleLeafContextClick, handleRightClick,
    combineTarget, toggleMarkDone, currentLeaf, renderLinks,
    selectedTaskIds, setSelectedTaskIds, leaves, dragInProgress, shared
}) => {

    const handleClick = (event) => {
        if (selectedTaskIds) {
            if (event.metaKey || event.ctrlKey) {
                event.preventDefault();
                if (leaf.locked)
                    return alert("This leaf is locked and can't be selected until it's unlocked.")
                setSelectedTaskIds(toggleSelected(selectedTaskIds, leaf._id));
                return;
            } else if (event.shiftKey) {
                setSelectedTaskIds(multiSelectTo(leaves, selectedTaskIds, leaf._id))
                event.preventDefault();
                return;
            }
        }
    }

    const toggleSelected = (selectedTaskIds, id) => {
        if (selectedTaskIds.indexOf(id) === -1) {
            return [...selectedTaskIds, id];
        } else {
            return selectedTaskIds.filter(i => i !== id);
        }
    }

    const multiSelectTo = (leaves, selectedTaskIds, id) => {
        const indexOfNew = leaves.findIndex(l => l._id === id);
        if (!selectedTaskIds.length) {
            if (leaves[indexOfNew].locked)
                return alert("This leaf is locked and can't be selected until it's unlocked.")
            return [id];
        }
        const lastSelectedId = selectedTaskIds[selectedTaskIds.length - 1];
        const indexOfLast = leaves.findIndex(l => l._id === lastSelectedId);

        if (indexOfNew === indexOfLast) {
            return selectedTaskIds;
        }

        const isSelectingForwards = indexOfNew > indexOfLast;
        const start = isSelectingForwards ? indexOfLast : indexOfNew;
        const end = isSelectingForwards ? indexOfNew : indexOfLast;

        const inBetween = leaves.slice(start, end + 1).filter(l => !l.locked).map(l => l._id);

        // everything in between needs to have it's selection toggled.
        // with the exception of the start and end values which will always be selected

        const toAdd = inBetween.filter(leafId => {
            // if already selected: then no need to select it again
            if (selectedTaskIds.includes(leafId)) {
                return false;
            }
            return true;
        });

        const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
        const combined = [...selectedTaskIds, ...sorted];

        return combined;
    }

    return leaf ?
        <Draggable
            draggableId={String(leaf._id)}
            index={index}
            isDragDisabled={leaf.locked === true}
        >
            {(provided, snapshot) => (
                currentLeaf.type === 'checklist' ?
                    <GinkgoChecklistItem
                        provided={provided}
                        snapshot={snapshot}
                        leaf={leaf}
                        onClick={handleClick}
                        toggleMarkDone={toggleMarkDone}
                        selectedTaskIds={selectedTaskIds}
                        onContextMenu={event => handleRightClick(event, JSON.stringify(leaf))}
                        dragInProgress={dragInProgress}
                        handleLeafContextClick={handleLeafContextClick}
                        currentLeaf={currentLeaf}
                        combineTarget={combineTarget}
                    />
                    : (leaf.linkTo === 'share') ?
                        <GinkgoShareButton
                            provided={provided}
                            snapshot={snapshot}
                            leaf={leaf}
                            shared={shared}
                            onClick={handleClick}
                            selectedTaskIds={selectedTaskIds}
                            onContextMenu={event => handleRightClick(event, JSON.stringify(leaf))}
                            dragInProgress={dragInProgress}
                            handleLeafContextClick={handleLeafContextClick}
                            currentLeaf={currentLeaf}
                            combineTarget={combineTarget}
                            renderLinks={renderLinks}
                            path={path}
                        />
                        :
                        (!leaf.linkOnlyToLeaf && (leaf.linkTo || validUrl(leaf.content))) ?
                            <GinkgoLink
                                provided={provided}
                                snapshot={snapshot}
                                leaf={leaf}
                                shared={shared}
                                onClick={handleClick}
                                selectedTaskIds={selectedTaskIds}
                                onContextMenu={event => handleRightClick(event, JSON.stringify(leaf))}
                                dragInProgress={dragInProgress}
                                handleLeafContextClick={handleLeafContextClick}
                                currentLeaf={currentLeaf}
                                combineTarget={combineTarget}
                            />
                            :
                            <GinkgoItem
                                provided={provided}
                                snapshot={snapshot}
                                leaf={leaf}
                                shared={shared}
                                onClick={handleClick}
                                selectedTaskIds={selectedTaskIds}
                                onContextMenu={event => handleRightClick(event, JSON.stringify(leaf))}
                                dragInProgress={dragInProgress}
                                handleLeafContextClick={handleLeafContextClick}
                                currentLeaf={currentLeaf}
                                combineTarget={combineTarget}
                                renderLinks={renderLinks}
                                path={path}
                            />
            )}
        </Draggable>
        :
        <Skeleton width="100%">
            <ListItem button>
                <ListItemText>.</ListItemText>
                <>
                    <ListItemSecondaryAction >
                        <IconButton size="large" />
                    </ListItemSecondaryAction>
                </>
            </ListItem>
        </Skeleton>;
}

export default DraggableListItem;