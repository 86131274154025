// material UI
import { Box, Typography } from "@mui/material";
//styles
import { wrapOverflow, respectWhitespace } from '../../styles/main';
// util
import { validImageUrl, validVideoUrl, youtubeUrl, getYoutubeVideoId, cleanUrl } from '../../util/url-checker';
import { isSafari } from '../../util/browser-utils';
// my components
import YoutubeEmbed from '../youtubeEmbed';

const ListItemContent = ({ leaf, showChildCounts }) => {
    let bold, code, header;
    if (leaf) {
        bold = Array.isArray(leaf.formats) && leaf.formats.indexOf('bold') > -1;
        code = Array.isArray(leaf.formats) && leaf.formats.indexOf('code') > -1;
        header = Array.isArray(leaf.formats) && leaf.formats.indexOf('header') > -1;
    }

    let displayContent = leaf.displayAs ? leaf.displayAs : leaf.content;

    return (
        <Box onClick={e => {
            // Prevent interaction with video controls from opening link in webkit browsers
            if (validVideoUrl(leaf.content) && isSafari) e.preventDefault()
        }}
            fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}
            fontFamily={code ? 'Cousine, monospace' : (leaf.fontFamily ?? ( 'fontFamily'))}
            fontSize={leaf.fontSize ?? (header ? 'h6.fontSize' : 'fontSize')}
            width='100%'
            style={{...wrapOverflow, ...respectWhitespace}}
        >
            {validImageUrl(leaf.content) ?
                <img src={leaf.content} alt="&nbsp;Whoops, there's no image at this link." style={{ maxWidth: '100%' }} />
                :
                validVideoUrl(leaf.content) ?
                    <video width="100%" height="100%" style={{ maxWidth: '100%' }} playsInline webkit-playsinline='true' preload='true' controls>
                        <source src={leaf.content + "#t=0.001"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    :
                    youtubeUrl(leaf.content) ?
                        <YoutubeEmbed embedId={getYoutubeVideoId(leaf.content)} />
                        :
                        (leaf.childCount && showChildCounts) ?
                            <>
                                {cleanUrl(displayContent) + ' '}
                                {/* <Chip size="small" variant="outlined" label={leaf.childCount} /> */}
                                <Typography variant="caption">
                                    {leaf.childCount}
                                </Typography>
                            </>
                            :
                            cleanUrl(displayContent)
            }
        </Box>
    );
}

export default ListItemContent;