import { useState } from 'react';
import { Box, Dialog, ToggleButton, ToggleButtonGroup, DialogTitle, Snackbar, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
// icons
import { ArrowForward, FileCopy, Lock, LockOpen, Notifications, RadioButtonUnchecked } from "@mui/icons-material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatSizeIcon from '@mui/icons-material/FormatSize';

// reducers
import LeafReducer from '../reducers/leaf';

import { Link as NavLink } from 'react-router-dom';
import { tomorrowMorning } from '../util/datetime';
import { deleteDeepSharedLeafChild, deleteLeaf, deleteManyLeaves, duplicateLeaf, scheduleReminder, updateDeepSharedLeaf, updateLeaf, updateLeafLock, updateManyLeaves, updateSharedLeaf } from '../util/leaf-functions';

import EditLeafDialog from './editLeafDialog';

const ListItemContextMenu = ({
    contextMenuOpen, setContextMenuOpen, leaves, setLeaves, anchorEl, setAnchorEl, token,
    linkShareId, anchorLeaf, setAnchorLeaf, currentLeaf,
    toggleMarkDone, contextMenuPosition, setContextMenuPosition,
    path, deepShared, selectedTaskIds, setSelectedTaskIds, renderLinks, useDeepAPI
}) => {
    const [showEditingDialogue, setShowEditingDialog] = useState(false);

    const [showReminderDialogue, setShowReminderDialog] = useState(false);
    const [copied, setCopied] = useState(false);
    const [reminderSnackbarOpen, setReminderSnackbarOpen] = useState(false);

    const isBulkOp = () => selectedTaskIds && selectedTaskIds.length && selectedTaskIds.includes(anchorLeaf._id);

    // Resetting the anchorLeaf hides this whole component, including the menu and the dialog.
    // If we want to show the dialog but hide the menu, we don't reset the anchorLeaf.
    const handleClose = (resetAnchorLeaf) => {
        if (resetAnchorLeaf) {
            setAnchorEl(null);
            setAnchorLeaf(null);
            setContextMenuPosition(null);
        }
        setContextMenuOpen(false)
        if (setSelectedTaskIds) setSelectedTaskIds([]);
    };

    const handleEditDialogOpen = (event) => {
        setShowEditingDialog(true);
        handleClose(false);
    }

    const handleEditDialogClose = (event) => {
        setShowEditingDialog(false);
        handleClose(true);
    }

    const handleReminderDialogOpen = (event) => {
        setShowReminderDialog(true);
        handleClose(false);
    }

    const handleReminderDialogClose = (event) => {
        setShowReminderDialog(false);
        handleClose(false);
    }

    const handleReminderSnackbarClose = (event) => {
        setReminderSnackbarOpen(false);
        handleClose(true);
    }

    const handleFormat = (event, newFormats) => {
        event.preventDefault();
        if (anchorLeaf) {
            let l = anchorLeaf;
            let rollbackLeaves = [...leaves];
            let rollbackFormats = l.formats;
            l.formats = newFormats;
            // let isBulkOp = selectedTaskIds && selectedTaskIds.length && selectedTaskIds.includes(l._id);
            let updateType = "formats";
            isBulkOp() ?
                setLeaves(leaves => LeafReducer.updateManyLeafFormats(leaves, { formats: newFormats }, selectedTaskIds)) :
                setLeaves(leaves => LeafReducer.updateLeaf(leaves, l));
            let updatePromise = token ?
                isBulkOp() ?
                    updateManyLeaves(selectedTaskIds, l, updateType, token) :
                    updateLeaf(l._id, l, token) :
                deepShared ?
                    updateDeepSharedLeaf(l._id, l, linkShareId) :
                    updateSharedLeaf(l._id, l, linkShareId);
            return updatePromise
                .then(response => {
                    console.log(response);
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("something went wrong with this request")
                    }
                })
                .catch(err => {
                    l.formats = rollbackFormats;
                    isBulkOp() ?
                        setLeaves(leaves => LeafReducer.updateManyLeafFormatsIndividually(leaves, rollbackLeaves, selectedTaskIds)) :
                        setLeaves(leaves => LeafReducer.updateLeaf(leaves, l));
                });
        }
    };

    const handleDuplicate = (event) => {
        event.preventDefault();
        if (anchorLeaf) {
            let l = anchorLeaf;
            let temp = JSON.parse(JSON.stringify(anchorLeaf));
            temp.rank += 10;
            setAnchorEl(null);
            setAnchorLeaf(null);
            temp._id = 'tempId:' + Math.random().toString();
            temp.disabled = true;
            setLeaves(leaves => LeafReducer.addLeaf(leaves, temp));
            let duplicationPromise =
                // Need to add BE support for duplicating via share link
                // renderLinks ?
                // duplicateDeepSharedLeafChild(l._id, linkShareId) :
                duplicateLeaf(l, token);
            return duplicationPromise
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else {
                        return Promise.reject('request failed');
                    }
                })
                .then(leaf => {
                    // need to use tempId or something to find the old leaf and replace it
                    setLeaves(leaves => LeafReducer.addLeaf(LeafReducer.removeLeaf(leaves, temp), leaf));
                })
                .catch((error) => {
                    setLeaves(leaves => LeafReducer.removeLeaf(leaves, temp));
                    console.error('Error:', error);
                })
        }
    };

    const handleScheduleReminder = ({ event, timeOffset, dateTime }) => {
        event.preventDefault();
        if (!anchorLeaf)
            return;

        let remindAt;
        if (timeOffset) {
            remindAt = Date.now() + timeOffset;
        } else if (dateTime) {
            remindAt = dateTime;
        } else {
            throw new Error("must be passed an object with timeOffset or dateTime key")
        }

        handleReminderDialogClose();

        return scheduleReminder(anchorLeaf._id, remindAt, token)
            .then(response => {
                if (response.status === 200) {
                    setReminderSnackbarOpen(true);
                    return true
                } else {
                    alert('Reminder not created');
                    return Promise.reject('request failed');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    const handleLockChange = (event, locked) => {
        event.preventDefault();
        if (!anchorLeaf)
            return;
        if (locked === false)
            locked = null; // tells API to unset
        let temp = JSON.parse(JSON.stringify(anchorLeaf));;
        setLeaves(leaves => LeafReducer.updateLeaf(leaves, { ...temp, locked: locked }));
        handleClose(true)

        return updateLeafLock(temp._id, locked, token)
            .then(response => {
                if (response.status === 200) {
                    return true
                } else {
                    return Promise.reject('request failed');
                }
            })
            .catch((error) => {
                setLeaves(leaves => LeafReducer.updateLeaf(leaves, temp));
                console.error('Error:', error);
            })
    }

    const handleDelete = (event) => {
        event.preventDefault();
        if (anchorLeaf) {
            let l = anchorLeaf;
            let rollbackLeaves = [...leaves];
            setAnchorEl(null);
            setAnchorLeaf(null);
            isBulkOp() ?
                setLeaves(leaves => LeafReducer.removeManyLeaves(leaves, selectedTaskIds)) :
                setLeaves(leaves => LeafReducer.removeLeaf(leaves, l));
            let deletionPromise = isBulkOp() ?
                deleteManyLeaves(selectedTaskIds, token) :
                renderLinks ?
                    deleteDeepSharedLeafChild(l._id, linkShareId) :
                    deleteLeaf(l._id, token);
            deletionPromise
                .then(response => {
                    if (response.status === 200) {
                        if (setSelectedTaskIds) setSelectedTaskIds([]);
                        response.json()
                            .then(newChildren => {
                                setLeaves(leaves => LeafReducer.addManyLeaves(leaves, newChildren));
                            })
                    } else {
                        console.error("deleting a leaf failed with non OK response");
                        throw new Error("something went wrong with this request")
                    }
                })
                .catch(err => {
                    isBulkOp() ?
                        setLeaves(leaves => LeafReducer.addManyLeaves(leaves, rollbackLeaves.filter(l => selectedTaskIds.includes(l._id)))) :
                        setLeaves(leaves => LeafReducer.addLeaf(leaves, l));
                });
        }
    };

    const copyToClipboard = (event) => {
        event.preventDefault();
        if (navigator.clipboard) {
            let textToCopy;
            if (isBulkOp()) {
                textToCopy = leaves.filter(leaf => selectedTaskIds.indexOf(leaf._id) !== -1).map(leaf => leaf.content).join('\n');
            } else {
                textToCopy = anchorLeaf.content;
            }
            navigator.clipboard.writeText(textToCopy);
            setCopied(true);
            setTimeout(() => setCopied(false), 700);
        }
    }

    return <>
        <Menu
            keepMounted
            open={contextMenuOpen && Boolean(anchorLeaf.locked !== true || token)}
            onClose={() => handleClose(true)}
            anchorReference={contextMenuPosition !== null ? 'anchorPosition' : 'anchorEl'}
            anchorEl={anchorEl ? anchorEl : undefined}
            anchorPosition={
                contextMenuPosition !== null
                    ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                    : undefined
            }
            variant='selectedMenu'
        >
            {anchorLeaf.locked === true && token ?
                <MenuItem onClick={(event) => handleLockChange(event, false)}>
                    <ListItemIcon>
                        <LockOpen />
                    </ListItemIcon>
                    Unlock leaf
                </MenuItem>
                :
                // To be keyboard navigable, this structure needs to be cleaned up so MenuItems are directly underneath Menu
                // <>
                [
                    ((token || renderLinks) &&
                        <Box mb={2} key='open leaf'>
                            <MenuItem
                                component={NavLink}
                                to={path + anchorLeaf._id} onClick={() => handleClose(true)}
                            >
                                <ListItemIcon>
                                    <ArrowForward />
                                </ListItemIcon>
                                Open leaf
                            </MenuItem>
                        </Box>
                    ),
                    (!isBulkOp() &&
                        <MenuItem onClick={handleEditDialogOpen} key='edit leaf'>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            Edit leaf
                        </MenuItem>
                    ),
                    <MenuItem autoFocus={false} disableRipple key='format leaf'>
                        <ListItemIcon>
                            <FormatPaintIcon />
                        </ListItemIcon>
                        <ToggleButtonGroup
                            size='small'
                            value={anchorLeaf.formats}
                            onChange={handleFormat}
                            aria-label="text formatting"
                        >
                            <ToggleButton value="header" aria-label="header">
                                <FormatSizeIcon />
                            </ToggleButton>
                            <ToggleButton value="bold" aria-label="bold">
                                <FormatBoldIcon />
                            </ToggleButton>
                            <ToggleButton value="code" aria-label="inline code">
                                <CodeIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </MenuItem>,
                    <MenuItem
                        onClick={e => toggleMarkDone(e, anchorLeaf)}
                        color='inherit'
                        key='mark leaf as done'
                        >
                        {anchorLeaf.done ?
                            <>
                                <ListItemIcon>
                                    <RadioButtonUnchecked />
                                </ListItemIcon>
                                Mark not done
                            </>
                            :
                            <>
                                <ListItemIcon style={{ color: 'mediumseagreen' }}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                Mark done
                            </>
                        }
                    </MenuItem>,
                    <Box 
                        key='schedule reminder'
                    >
                        {(!isBulkOp() && token) &&
                            <MenuItem data-testid="schedule-reminder-menu-item"
                                onClick={handleReminderDialogOpen} >
                                <ListItemIcon>
                                    <Notifications />
                                </ListItemIcon>
                                Set reminder
                            </MenuItem>
                        }
                        {(!isBulkOp() && token) &&
                            <MenuItem data-testid="duplicate-leaf-menu-item"
                                onClick={handleDuplicate} >
                                <ListItemIcon>
                                    <FileCopy />
                                </ListItemIcon>
                                Duplicate leaf
                            </MenuItem>
                        }
                        {token && !isBulkOp() &&
                            <MenuItem onClick={(event) => handleLockChange(event, true)}>
                                <ListItemIcon>
                                    <Lock />
                                </ListItemIcon>
                                Lock leaf
                            </MenuItem>
                        }
                        {navigator.clipboard &&
                            <MenuItem disabled={copied ? true : false} onClick={copyToClipboard}>
                                {copied ?
                                    <>
                                        <ListItemIcon>
                                            <AssignmentTurnedInIcon />
                                        </ListItemIcon>
                                        Copied
                                    </>
                                    :
                                    <>
                                        <ListItemIcon>
                                            <AssignmentIcon />
                                        </ListItemIcon>
                                        Copy content
                                    </>
                                }
                            </MenuItem>
                        }
                        {(token || renderLinks) &&
                            <MenuItem 
                                data-testid="delete-leaf-menu-item"
                                onClick={handleDelete}
                                key='delete leaf'
                                sx={{ color: 'firebrick' }}>
                                <ListItemIcon>
                                    <DeleteIcon sx={{ color: 'firebrick' }} />
                                </ListItemIcon>
                                Delete {selectedTaskIds && selectedTaskIds.length > 1 && selectedTaskIds.includes(anchorLeaf._id) && ` ${selectedTaskIds.length} leaves`}
                            </MenuItem>
                        }
                    </Box>
                ]
                // </>
            }
        </Menu>
        <EditLeafDialog
            open={showEditingDialogue}
            setLeaves={setLeaves}
            token={token}
            linkShareId={linkShareId}
            anchorLeaf={anchorLeaf}
            currentLeaf={currentLeaf}
            useDeepAPI={useDeepAPI}
            handleClose={handleEditDialogClose}
            aria-labelledby="form-dialog-title"
            fullWidth maxWidth="sm"
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'baseline'
                }
            }}
        />
        <Dialog
            open={showReminderDialogue}
            onClose={handleReminderDialogClose}
            aria-labelledby="reminder-dialog"
            maxWidth="xs" fullWidth >
            <DialogTitle id="form-dialog-title">Remind me</DialogTitle>
            <List>
                {(window.location.hostname === 'localhost') &&
                    <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 15 })} >
                        <ListItemText>
                            in 15 seconds
                        </ListItemText>
                    </ListItem>
                }
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 60 * 15 })} >
                    <ListItemText>
                        in 15 minutes
                    </ListItemText>
                </ListItem>
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 3600 })} >
                    <ListItemText>
                        in 1 hour
                    </ListItemText>
                </ListItem>
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 3600 * 3 })} >
                    <ListItemText>
                        in 4 hours
                    </ListItemText>
                </ListItem>
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, dateTime: tomorrowMorning() })} >
                    <ListItemText>
                        tomorrow morning
                    </ListItemText>
                </ListItem>
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 3600 * 24 * 7 })} >
                    <ListItemText>
                        in a week
                    </ListItemText>
                </ListItem>
                <ListItem button onClick={(e) => handleScheduleReminder({ event: e, timeOffset: 1000 * 3600 * 24 * 30 })} >
                    <ListItemText>
                        in a month
                    </ListItemText>
                </ListItem>
            </List>
        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={reminderSnackbarOpen}
            autoHideDuration={4000}
            onClose={handleReminderSnackbarClose}
            message="Email reminder created"
        />
    </>;
}

export default ListItemContextMenu;