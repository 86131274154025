import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// my components
import Leaf from "./components/leaf";
import SharedLeaf from "./components/sharedLeaf";
import DeepSharedLeaf from "./components/deepSharedLeaf";
import Login from "./components/login";
import LoginLinkLander from "./components/loginLinkLander";
import PasswordResetLander from "./components/passwordResetLander";
import PasswordResetRequest from "./components/passwordResetRequest";
import SecureShareLander from "./components/secureShareLander";
// other
import { DragDropContext } from 'react-beautiful-dnd';
import { Container } from "@mui/material";
import { io } from "socket.io-client";
// reducers
import LeafReducer from './reducers/leaf';
// util
import { setLeafParent, updateManyLeavesParent, updateRank, updateManyLeavesRanks, updateSharedRank, updateDeepSharedRank, setDeepSharedLeafParent } from './util/leaf-functions';
import { tabId } from './util/tab-id';
import OfflineWarning from "./components/offlineWarning";

function App({ setBackgroundColor }) {
  const [currentLeaf, setCurrentLeaf] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [dragInProgress, setDragInProgress] = useState(false);
  const [combineTarget, setCombineTarget] = useState(null); // show hover style on combine target
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [linkShareId, setLinkshareId] = useState(false);
  const [deepShared, setDeepShared] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  let socket = useRef(false);

  useEffect(() => {

    if (socket.current && currentLeaf) {
      // console.log('adding socket listeners')
      socket.current.on('leaf-updated', leaf => {
        if (tabId === leaf.tabId)
          return;
        if (leaf._id === currentLeaf._id) {
          setCurrentLeaf({
            ...currentLeaf,
            dense: leaf.dense,
            type: leaf.type,
            combine: leaf.combine
          });
        } else {
          let newLeaves = JSON.parse(JSON.stringify(leaves));
          // hide the updated leaf if it was moved
          if (leaf.parentId !== currentLeaf._id) {
            newLeaves = newLeaves.filter(l => l._id !== leaf._id)
            // update childCount of the new parent if it's being displayed
            let newParentLeaf = newLeaves.find(l => l._id === leaf.parentId);
            if (newParentLeaf) {
              if (!newParentLeaf.childCount)
                newParentLeaf.childCount = 0;
              newParentLeaf.childCount += 1;
            }
            // console.log(newParentLeaf);
            // setLeaves(leaves => LeafReducer.updateLeaf(leaves, newParentLeaf))
          } else {
            let index = newLeaves.findIndex(l => l._id === leaf._id);
            if (index === -1) {
              // add leaf if it's missing
              newLeaves.push(leaf);
              // decrement childCount of old parent if leaf was moved out of another leaf that's being displayed
              let formerParentLeaf = newLeaves.find(l => l._id === leaf.originalParentId);
              if (formerParentLeaf) {
                if (formerParentLeaf.childCount <= 1) {
                  delete formerParentLeaf.childCount;
                } else {
                  formerParentLeaf.childCount -= 1;
                }
              }
            } else {
              // update it if it's there
              newLeaves[index] = { ...newLeaves[index], ...leaf };
            }
          }
          setLeaves(newLeaves);
        }
      })

      socket.current.on('leaves-formats-updated', update => {
        let { ids, ...changes } = update;
        setLeaves(leaves => LeafReducer.updateManyLeafFormats(leaves, changes, ids))
      })

      socket.current.on('leaves-ranks-updated', movers => {
        setLeaves(leaves => LeafReducer.updateManyLeavesRanks(leaves, movers));
      })

      socket.current.on('leaves-parent-updated', update => {
        if (tabId === update.tabId)
          return;
        let newLeaves = JSON.parse(JSON.stringify(leaves));
        let ids = update.ids;
        let parentId = update.parentId;
        let updatedLeaves = update.updatedLeaves;

        if (parentId === currentLeaf._id) {
          let formerParentLeaf = newLeaves.find(l => l._id === update.originalParentId);
          if (formerParentLeaf) {
            if (formerParentLeaf.childCount <= ids.length) {
              delete formerParentLeaf.childCount;
            } else {
              formerParentLeaf.childCount -= ids.length;
            }
          }
          newLeaves = LeafReducer.addManyLeaves(newLeaves, updatedLeaves);
        } else {
          let newParentLeaf = newLeaves.find(l => l._id === update.parentId);
          if (newParentLeaf) {
            if (!newParentLeaf.childCount) {
              newParentLeaf.childCount = 0;
            }
            newParentLeaf.childCount += ids.length;
          }
          newLeaves = LeafReducer.removeManyLeaves(newLeaves, ids)
        }
        setLeaves(newLeaves);
      })

      socket.current.on('leaf-added', leaf => {
        if (tabId === leaf.tabId)
          return;
        let newLeaves = [...leaves];
        let tempIdIndex = newLeaves.findIndex(l => l._id === leaf.tempId);
        let index = newLeaves.findIndex(l => l._id === leaf._id);
        // add leaf for clients that didn't optimistically add it
        if (index === -1 && tempIdIndex === -1) {
          setLeaves(leaves => LeafReducer.addLeaf(leaves, leaf));
        }
      })

      socket.current.on('leaf-deleted', ({ deletedLeaf, newChildren }) => {
        setLeaves(leaves => {
          let newLeaves = LeafReducer.removeLeaf(leaves, deletedLeaf);
          return LeafReducer.addManyLeaves(newLeaves, newChildren);
        })
      })

      socket.current.on('leaves-deleted', ({ deletedLeavesIds, newChildren }) => {
        let newLeaves = LeafReducer.removeManyLeaves(leaves, deletedLeavesIds);
        setLeaves(leaves => LeafReducer.addManyLeaves(newLeaves, newChildren));
      })

      socket.current.on('update-rank', updatedLeaf => {
        setLeaves(leaves => LeafReducer.updateLeafRank(leaves, updatedLeaf));
      })
    }

    return () => {
      if (socket.current && currentLeaf) {
        socket.current.off('leaf-updated');
        socket.current.off('leaves-formats-updated');
        socket.current.off('leaves-ranks-updated');
        socket.current.off('leaves-parent-updated');
        socket.current.off('leaf-added');
        socket.current.off('leaf-deleted');
        socket.current.off('leaves-deleted');
        socket.current.off('update-rank');
      }
    };


  }, [leaves, currentLeaf]);

  useEffect(() => {
    if ((token !== null || linkShareId) && !socket.current) {
      // console.log('initializing socket')
      socket.current = io({
        auth: {
          token: token || false,
          linkshareId: linkShareId || false
        }
      });
    }
  }, [token, linkShareId])

  useEffect(() => {
    if (currentLeaf && socket.current) {
      // console.log('trying to join room ' + currentLeaf._id);
      socket.current.emit('join', currentLeaf._id);
    }
  }, [currentLeaf])

  useEffect(() => { 
    if (currentLeaf && currentLeaf.pageBackground) {
      setBackgroundColor(currentLeaf.pageBackground)
    } else {
      setBackgroundColor(null)
    }
  }, [currentLeaf])

  const onDragStart = () => {
    setDragInProgress(true);
  }

  const onDragUpdate = (update) => {
    if (update.combine)
      setCombineTarget(update.combine.draggableId);
    else
      setCombineTarget(false);
  }

  const onDragEnd = result => {
    setDragInProgress(false);
    setCombineTarget(false);

    const { destination, source, combine } = result;

    // should be a combine or have a destination that's different than where it started
    if (!combine &&
      (!destination || (source.index === destination.index &&
        source.droppableId === destination.droppableId))) {
      return;
    }

    var originalLeaves = JSON.parse(JSON.stringify(leaves));
    originalLeaves = originalLeaves.sort((x, y) => x.rank > y.rank ? 1 : -1);

    let originalMover = { ...originalLeaves[source.index] }; // we'll keep this and use it to revert
    let mover = originalLeaves[source.index];
    let isBulkOp = () => selectedTaskIds && selectedTaskIds.length && selectedTaskIds.includes(mover._id);

    // moving into breadcrumb nav item
    if (destination && destination.droppableId !== 'leaf-list') {
      let newParentId = destination.droppableId;
      let originalParentId = mover.parentId;
      isBulkOp() ?
        setLeaves(leaves => LeafReducer.removeManyLeaves(leaves, selectedTaskIds)) :
        setLeaves(leaves => LeafReducer.removeLeaf(leaves, mover));
      // move setParentPromise up and remove duplicate below if possible
      let setParentPromise = currentLeaf.shareDeep ?
        setDeepSharedLeafParent(mover._id, newParentId, originalParentId, linkShareId) :
        isBulkOp() ?
          updateManyLeavesParent(selectedTaskIds, newParentId, originalParentId, token) :
          setLeafParent(mover._id, newParentId, originalParentId, token);
      setParentPromise
        .then(response => {
          if (response.status === 200) {
            setSelectedTaskIds([]);
          } else {
            console.error("moving leaf/s into breadcrumb item failed with non OK response");
            throw new Error("something went wrong with this request")
          }
        })
        .catch(err => {
          isBulkOp() ?
            setLeaves(leaves => LeafReducer.addManyLeaves(leaves, originalLeaves.filter(l => selectedTaskIds.includes(l._id)))) :
            setLeaves(leaves => LeafReducer.addLeaf(mover));
        });
      return;
      // moving into another leaf in the list
    } else if (combine) {
      // this is mostly redundant with the code above, should be cleaned up
      let newParentId = combine.draggableId;
      let originalParentId = mover.parentId;

      isBulkOp() ?
        setLeaves(leaves => LeafReducer.removeManyLeaves(leaves, selectedTaskIds)) :
        setLeaves(leaves => LeafReducer.removeLeaf(leaves, mover));
      let newParentLeafBeforeCombining = originalLeaves.find(l => l._id === newParentId);
      let newParentLeaf = { ...newParentLeafBeforeCombining };
      if (!newParentLeaf.childCount) newParentLeaf.childCount = 0;
      if (selectedTaskIds.length) {
        newParentLeaf.childCount += selectedTaskIds.length;
      } else {
        newParentLeaf.childCount += 1;
      }
      setLeaves(leaves => LeafReducer.updateLeaf(leaves, newParentLeaf))
      let setParentPromise = currentLeaf.shareDeep ?
        setDeepSharedLeafParent(mover._id, newParentId, originalParentId, linkShareId) :
        isBulkOp() ?
          updateManyLeavesParent(selectedTaskIds, newParentId, originalParentId, token) :
          setLeafParent(mover._id, newParentId, originalParentId, token);
      setParentPromise
        .then(response => {
          if (response.status === 200) {
            setSelectedTaskIds([]);
          } else {
            console.error("moving leaf/s into another leaf failed with non OK response");
            throw new Error("something went wrong with this request");
          }
        })
        .catch(err => {
          isBulkOp() ?
            setLeaves(leaves => LeafReducer.addManyLeaves(leaves, originalLeaves.filter(l => selectedTaskIds.includes(l._id)))) :
            setLeaves(leaves => LeafReducer.addLeaf(leaves, mover));
          setLeaves(leaves => LeafReducer.updateLeaf(leaves, newParentLeafBeforeCombining))
        });
    } else {
      let above, below = null;
      if (destination.index === 0) {
        // moving to top of list
        below = originalLeaves[0];
        // d[moverIndex].rank = targetItem.rank - 100;
        // mover.rank = targetItem.rank - 100 - 10 * Math.random();
      } else if (destination.index === originalLeaves.length - 1) {
        // moving to bottom of list
        above = originalLeaves[originalLeaves.length - 1];
        // d[moverIndex].rank = targetItem.rank + 100;
        // mover.rank = targetItem.rank + 100 + 10 * Math.random();
      } else {
        // moving to somewhere in middle of list
        if (destination.index > source.index) {
          // moving item down
          above = originalLeaves[destination.index]
          below = originalLeaves[destination.index + 1]
        } else {
          // moving item up
          above = originalLeaves[destination.index - 1]
          below = originalLeaves[destination.index]
        }
      }

      let movers = JSON.parse(JSON.stringify(originalLeaves))
      if (isBulkOp()) {
        movers = movers.filter(l => selectedTaskIds.includes(l._id));
        let rankGap = 100, createNewRank;
        if (above) {
          if (below) {
            rankGap = (below.rank - above.rank) / ((movers.length + 1) * 1.0);
          }
          createNewRank = i => above.rank + ((i + 1) * rankGap);
        } else {
          createNewRank = i => below.rank - (movers.length * rankGap) + (i * rankGap);
        }
        for (let i = 0; i < movers.length; i++) {
          movers[i].rank = createNewRank(i);
        }
        setLeaves(leaves => LeafReducer.updateManyLeavesRanks(leaves, movers));
      } else {
        let newRank;
        if (above && below) {
          newRank = (above.rank * 1.0 + below.rank * 1.0) / 2;
          if (newRank == above.rank || newRank == below.rank) {
            // Need to balance leaf ranks on FE for optimistic update. Done same way on BE.
            // find lowest rank among them
            movers.sort((x, y) => x.rank > y.rank ? 1 : -1);
            let lowestRank = movers[0]?.rank;
            // set each rank to be 100 greater than the next lowest leaf
            for (let i = 1; i < movers.length; i++) {
              movers[i].rank = lowestRank + (i * 100)
            }
            above = movers.find(leaf => leaf._id === above._id);
            below = movers.find(leaf => leaf._id === below._id);
            newRank = (above.rank * 1.0 + below.rank * 1.0) / 2;
            setLeaves(leaves => LeafReducer.updateManyLeavesRanks(leaves, movers))
          }

          // newRank = below.rank
        } else if (above) {
          // dropped at bottom
          newRank = above.rank + 100
        } else if (below) {
          // dropped at top
          newRank = below.rank - 100
        } else {
          throw new Error("neither leaf above nor leaf below passed");
        }
        mover.rank = newRank;
        setLeaves(leaves => LeafReducer.updateLeaf(leaves, mover));
      }

      let updatePromise = linkShareId ?
        deepShared ?
          updateDeepSharedRank(mover._id, above ? above._id : null, below ? below._id : null, linkShareId) :
          updateSharedRank(mover._id, above ? above._id : null, below ? below._id : null, linkShareId) :
        isBulkOp() ?
          updateManyLeavesRanks(selectedTaskIds, above ? above._id : null, below ? below._id : null, token) :
          updateRank(mover._id, above ? above._id : null, below ? below._id : null, token)

      updatePromise
        .then(res => {
          if (res.status !== 200) {
            console.log('bulk rank update failed!');
            throw new Error("something went wrong with this request");
          }
        })
        .catch(err => {
          isBulkOp() ?
            setLeaves(leaves => LeafReducer.updateManyLeavesRanks(leaves, originalLeaves.filter(l => selectedTaskIds.includes(l._id)))) :
            setLeaves(leaves => LeafReducer.updateLeafRank(leaves, originalMover))
        });
    }

  }

  // const handlePaste = (e) => {
  //   // will use this to fill the input later when pasting anywhere on the page
  //   if (e.clipboardData.files.length) {
  //     const fileObject = e.clipboardData.files[0];
  //     const file = {
  //       getRawFile: () => fileObject,
  //       name: fileObject.name,
  //       size: fileObject.size,
  //     };
  //     // console.log(fileObject)
  //   }
  // }

  return (
    <>
      <OfflineWarning />
      <Container maxWidth="sm"
      // onPaste={handlePaste}
      >
        <DragDropContext
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}>
          <Router>
            <Switch>
              { /* this needs to be fixed to handle signed in users via a redirect or something */}
              <Route path="/secure-share/:secureShareToken/:leafId">
                <DeepSharedLeaf
                  secureShare={true}
                  setLinkshareId={setLinkshareId}
                  leaves={leaves}
                  setLeaves={setLeaves}
                  currentLeaf={currentLeaf}
                  setCurrentLeaf={setCurrentLeaf}
                  token={token}
                  linkShareId={linkShareId}
                  setDeepShared={setDeepShared}
                  combineTarget={combineTarget}
                />
              </Route>
              <Route path="/secure-share/:secureShareToken">
                {linkShareId ?
                  <SharedLeaf
                    secureShare={true}
                    setLinkshareId={setLinkshareId}
                    leaves={leaves}
                    setLeaves={setLeaves}
                    currentLeaf={currentLeaf}
                    setCurrentLeaf={setCurrentLeaf}
                    token={token}
                    linkShareId={linkShareId}
                    setDeepShared={setDeepShared}
                    combineTarget={combineTarget}
                  />
                  :
                  <SecureShareLander setLinkshareId={setLinkshareId} ></SecureShareLander>
                }

              </Route>
              <Route path="/share/:linkshareId/:leafId">
                <DeepSharedLeaf
                  setLinkshareId={setLinkshareId}
                  leaves={leaves}
                  setLeaves={setLeaves}
                  currentLeaf={currentLeaf}
                  setCurrentLeaf={setCurrentLeaf}
                  token={token}
                  linkShareId={linkShareId}
                  setDeepShared={setDeepShared}
                  combineTarget={combineTarget}
                />
              </Route>
              <Route path="/share/:linkshareId">
                <SharedLeaf
                  setLinkshareId={setLinkshareId}
                  leaves={leaves}
                  setLeaves={setLeaves}
                  currentLeaf={currentLeaf}
                  setCurrentLeaf={setCurrentLeaf}
                  token={token}
                  linkShareId={linkShareId}
                  setDeepShared={setDeepShared}
                  combineTarget={combineTarget}
                />
              </Route>
              <Route path="/email-login/:emailToken">
                <LoginLinkLander setToken={setToken}></LoginLinkLander>
              </Route>
              <Route path="/reset-password/:resetToken">
                <PasswordResetLander></PasswordResetLander>
              </Route>
              <Route path="/request-reset/:emailAddress?">
                <PasswordResetRequest></PasswordResetRequest>
              </Route>
              <Route>
                {({ location }) => {
                  let path = location.pathname;
                  let id;
                  if (path === '/') {
                    id = 'home';
                  } else {
                    id = path.replace('/', '')
                  }
                  if (!token) {
                    return (
                      <Login setToken={setToken}></Login>
                    )
                  } else return (
                    <Leaf
                      setLinkshareId={setLinkshareId} // just sets it to false when not viewing a shared leaf
                      id={id}
                      leaves={leaves}
                      setLeaves={setLeaves}
                      dragInProgress={dragInProgress}
                      combineTarget={combineTarget}
                      token={token}
                      setToken={setToken}
                      currentLeaf={currentLeaf}
                      setCurrentLeaf={setCurrentLeaf}
                      setDeepShared={setDeepShared}
                      selectedTaskIds={selectedTaskIds}
                      setSelectedTaskIds={setSelectedTaskIds}
                    />
                  );
                }}
              </Route>
            </Switch>
          </Router>
        </DragDropContext>
      </Container>
    </>
  );
}

export default App;
