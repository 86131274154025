// material UI
import {
    List,
    Box
} from "@mui/material";
// icons
// third party libs
import React from 'react';
import LeafSettingsMenu from "./leafSettingsMenu";
// util
import { sortLeaves } from "../util/sort-leaves";
// my components
import ReadOnlyListItem from "./readOnlyListItem";

const ReadOnlyLeaf = ({ leaves, currentLeaf, setCurrentLeaf, linkShareId, renderLinks, path }) => {

    return (
        <>
            {!currentLeaf || !leaves ?
                <> {/* show nothing for now for loading */}
                </>
                // <Typography color="textPrimary" >
                //     <br/><br/> 🌱 Loading...
                // </Typography>
                :
                <>
                    {typeof leaves === 'object' && leaves !== null &&
                        <>
                            <LeafSettingsMenu
                                currentLeaf={currentLeaf}
                                setCurrentLeaf={setCurrentLeaf}
                                linkShareId={linkShareId}
                            />
                            {/* shared leaf is not editable */}
                            <List
                                dense={currentLeaf && currentLeaf.dense}
                            >
                                <Box mt={2}>
                                    {leaves
                                        // .filter(leaf => leaf.parentId === id)
                                        .sort(sortLeaves)
                                        .map((leaf, index) => (
                                            <ReadOnlyListItem
                                                leaf={leaf}
                                                path={path}
                                                currentLeaf={currentLeaf}
                                                key={leaf._id}
                                                renderLinks={renderLinks}
                                            />
                                        ))}
                                </Box>
                            </List>
                        </>
                    }
                </>
            }
        </>
    )

}

export default ReadOnlyLeaf;