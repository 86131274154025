import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => {

    const containerStyle = {
        maxWidth: '100%'
    }

    const frameContainerStyle = {
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: '0'
    };

    const iFrameStyle = {
        left: '0',
        top: '0',
        height: '100%',
        width: '100%',
        position: 'absolute',
    };

    return (
        <div style={containerStyle} >
            <div style={frameContainerStyle} >
                <iframe
                    style={iFrameStyle}
                    width="853"
                    height="480"
                    src={`https://www.youtube-nocookie.com/embed/${embedId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    )
};

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
