// material UI
import {
    ListItem,
    ListItemText,
    ListItemIcon
} from '@mui/material';
// icons
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
// styles
import { makeStyle } from '../../styles/listItemText';
import { makeListItemStyle } from '../../styles/listItem';
// util
import { validImageUrl } from '../../util/url-checker';
// my components
import ListItemContent from './listItemContent';
import ContextMenuButton from './contextMenuButton';

export default function GinkgoChecklistItem({
    leaf, onClick, onContextMenu, handleLeafContextClick,
    combineTarget, currentLeaf, toggleMarkDone,
    selectedTaskIds, dragInProgress, shared, provided, snapshot
}) {

    let header;
    if (leaf) {
        header = Array.isArray(leaf.formats) && leaf.formats.indexOf('header') > -1;
    }
    let hideContextMenu = shared && leaf.locked;

    return (
        <ListItem
            key={leaf._id}
            data-testid="list-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            button={leaf.locked && validImageUrl(leaf.content) ? false : true}
            disabled={leaf.disabled}
            selected={selectedTaskIds ? selectedTaskIds.indexOf(leaf._id) !== -1 : false}
            onClick={e => {
                onClick(e)
                // don't toggle done when user opens the context menu
                if (['div', 'li', 'span'].includes(e.target.localName) || e.target.id === 'checkbox-icon' ||
                    (e.target.outerHTML && e.target.outerHTML.indexOf('M12 2C6') !== -1)) {
                    if (leaf.locked === true)
                        return
                    //     return alert('This leaf is locked and can\'t be updated until it\'s unlocked.')
                    toggleMarkDone(e, leaf);
                    return
                }
            }}
            onContextMenu={onContextMenu}
            style={{
                ...provided.draggableProps.style,
                ...makeListItemStyle({
                    snapshot: snapshot,
                    leaf: leaf,
                    combineTarget: combineTarget,
                    selectedTaskIds: selectedTaskIds,
                    isLink: false
                })
            }}
        >
            {!header &&
                <>
                    {leaf.done ?
                        <ListItemIcon style={{ color: 'mediumseagreen' }} >
                            <CheckCircle id="checkbox-icon" />
                        </ListItemIcon>
                        :
                        <ListItemIcon >
                            <RadioButtonUnchecked id="checkbox-icon" />
                        </ListItemIcon>

                    }
                </>
            }
            <ListItemText style={makeStyle({
                dragInProgress: dragInProgress,
                snapshot: snapshot,
                leaf: leaf,
                selectedTaskIds: selectedTaskIds,
                shared: shared,
                currentLeaf: currentLeaf
            })}>
                <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
            </ListItemText>
            { /* need this fragment to make react-beautiful-dnd work correctly */}
            {!hideContextMenu &&
                <ContextMenuButton
                    leaf={leaf}
                    onClick={handleLeafContextClick}
                    snapshot={snapshot}
                    selectedTaskIds={selectedTaskIds}
                />
            }
        </ListItem>
    )
}