import { Box, ListItemSecondaryAction, IconButton } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

import CenteredBadge from './centeredBadge'

export default function ContextMenuButton({leaf, onClick, snapshot, selectedTaskIds}) {
    return (
        <Box>
            <ListItemSecondaryAction
                data-testid="leaf-context-menu"
            >
                <IconButton
                    id="leaf-context-menu"
                    edge="end"
                    aria-label="more options"
                    value={JSON.stringify(leaf)}
                    onClick={onClick}
                    tabIndex={-1}
                    size="large">
                    <CenteredBadge invisible={!snapshot.isDragging || !selectedTaskIds || !selectedTaskIds[0] || !selectedTaskIds.includes(leaf._id)} badgeContent={selectedTaskIds ? selectedTaskIds.length : null} color="primary">
                        <MoreHoriz/>
                    </CenteredBadge>
                </IconButton>
            </ListItemSecondaryAction>
        </Box>
    )
}