exports.sortLeaves = (x, y) => { 
    if(x.rank > y.rank)
        return 1;
    if(y.rank > x.rank)
        return -1;
    // fallback that should never be used - just in case ranks are somehow the same this precen
    
    if(window.location.host.includes('localhost'))
        alert('WARNING: Two leaves have the same rank. \n This warning only shows on localhost.');
    return x.content > y.content ? 1 : -1;
}