// material UI
import { List, Box } from "@mui/material";

import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import confetti from 'canvas-confetti';
// util
import { addLeaf, updateLeaf, updateManyLeaves } from '../util/leaf-functions';
import { sortLeaves } from "../util/sort-leaves";
// reducers
import LeafReducer from '../reducers/leaf';
// my components
import BreadcrumbNav from "./breadcrumbNav";
import LeafSettingsMenu from "./leafSettingsMenu";
import ListItemContextMenu from "./listItemContextMenu";
// import DraggableListItem from "./draggableListItem";
import DraggableListItem from "./list-item/draggableListItem";
import UndoSnackbar from "./undoSnackbar";
import NotFound from "./404";
import TextInput from "./TextInput";
import { updatePwaAssets } from "../util/pwa-utils";

const Leaf = ({
    id, dragInProgress, combineTarget, leaves, setLeaves,
    token, setToken, setLinkshareId, currentLeaf, setCurrentLeaf, setDeepShared,
    selectedTaskIds, setSelectedTaskIds
}) => {
    const [loading, setLoading] = useState(true);
    const [linkShare, setLinkShare] = useState(false);
    // anchors for context menus
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLeaf, setAnchorLeaf] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    // state of context menu items
    const [leafPendingDeletion, setLeafPendingDeletion] = useState(null);
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    // get settings for current leaf
    useEffect(() => {
        setSelectedTaskIds([]);
        setLinkshareId(false);
        setDeepShared(false);
        console.log("get current leaf called");
        if (!token) return;
        setLoading(true);
        setCurrentLeaf(null);
        fetch('/api/leaves/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json())
            .then(leaf => {
                document.title = leaf.currentLeaf.content;
                setCurrentLeaf(leaf.currentLeaf);
                setLeaves(leaf.leaves);
                setLinkShare(false);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                // if (id === 'home') {
                //     logOut();
                // }
                setLoading(false);
            })
    }, [token, id, setLeaves]);

    useEffect(() => {
        updatePwaAssets({
            faviconUrl: currentLeaf?.faviconUrl,
            appleTouchIconUrl: currentLeaf?.appleTouchIconUrl,
            appName: currentLeaf?.appName,
            appBackground: currentLeaf?.pageBackground
        })

    }, [currentLeaf])

    // get linkshare for current leaf
    useEffect(() => {
        if (!token || !currentLeaf) return;
        // console.log("get share link called");
        fetch('/api/leaves/share/' + currentLeaf._id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.status === 404) return;
                else return response.json();
            })
            .then(newLinkShare => {
                setLinkShare(newLinkShare);
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }, [currentLeaf]);

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        window.addEventListener('keydown', onWindowKeyDown);
        window.addEventListener('touchend', onWindowTouchEnd);
        return (() => {
            window.removeEventListener('click', onWindowClick);
            window.removeEventListener('keydown', onWindowKeyDown);
            window.removeEventListener('touchend', onWindowTouchEnd);
        })
    }, [])

    const handleLeafContextClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        if (event.currentTarget.value)
            setAnchorLeaf(JSON.parse(event.currentTarget.value));
        setContextMenuOpen(true);
    };

    const handleRightClick = (event, leaf) => {
        event.preventDefault();
        setAnchorLeaf(JSON.parse(leaf));
        setContextMenuPosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setContextMenuOpen(true);
    };

    const toggleMarkDone = (e, leaf) => {
        e.preventDefault();
        if (leaf) {
            if (!leaf.done) {
                confetti({
                    startVelocity: 13,
                    origin: {
                        x: e.clientX / window.innerWidth,
                        y: e.clientY / window.innerHeight - (contextMenuOpen ? 0.07 : 0)
                    },
                    spread: 300,
                    ticks: 80,
                    zIndex: 1000
                });
            }
            let rollbackLeaves = JSON.parse(JSON.stringify(leaves));
            let l = leaf;
            l.done = !l.done;
            let updatePromise;
            let isBulkOp = selectedTaskIds.length && selectedTaskIds.includes(leaf._id);
            if (isBulkOp) {
                setLeaves(leaves => LeafReducer.updateManyLeafFormats(leaves, { done: l.done }, selectedTaskIds));
                let updateType = "done";
                updatePromise = updateManyLeaves(selectedTaskIds, l, updateType, token);
            } else {
                setLeaves(leaves => LeafReducer.updateLeaf(leaves, l)); // optimistic update
                updatePromise = updateLeaf(l._id, l, token)
            }
            updatePromise
                .then(response => {
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request to mark leaf as done");
                    }
                })
                .catch(err => {
                    // roll back optimistic update
                    l.done = !l.done;
                    isBulkOp ?
                        setLeaves(leaves => LeafReducer.updateManyLeafFormatsIndividually(leaves, rollbackLeaves, selectedTaskIds)) :
                        setLeaves(leaves => LeafReducer.updateLeaf(leaves, l));
                });
        }
    }

    // support functions for multi-select
    let unselectAll = () => {
        setSelectedTaskIds([]);
    };

    let onWindowKeyDown = (event) => {
        if (event.defaultPrevented) {
            return;
        }
        if (event.key === 'Escape') {
            unselectAll();
        }
    };

    let onWindowClick = (event) => {
        if (event.defaultPrevented) {
            return;
        }
        unselectAll();
    };

    let onWindowTouchEnd = (event) => {
        if (event.defaultPrevented) {
            return;
        }
        unselectAll();
    };

    return (
        <>
            {!currentLeaf || !leaves ?
                <>
                    {loading ?
                        <>
                            <BreadcrumbNav />
                            <LeafSettingsMenu />
                            <Box mt={2}>
                                <List>
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                </List>
                                <TextInput />
                            </Box>
                        </>
                        :
                        <NotFound setToken={setToken} />
                    }
                </>
                :
                <>
                    <BreadcrumbNav currentLeaf={currentLeaf} />
                    <LeafSettingsMenu
                        currentLeaf={currentLeaf}
                        setCurrentLeaf={setCurrentLeaf}
                        token={token}
                        setToken={setToken}
                        linkShare={linkShare}
                        setLinkShare={setLinkShare}
                    />
                    {typeof leaves === 'object' && leaves !== null &&
                        <Droppable droppableId="leaf-list" isCombineEnabled={currentLeaf.combine === undefined ? true : Boolean(currentLeaf.combine)}>
                            {// render children of current post
                                (provided) => (
                                    <List
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        dense={currentLeaf && currentLeaf.dense}
                                    >
                                        <Box mt={2} >
                                            {leaves
                                                // .filter(leaf => leaf.parentId === id)
                                                .sort(sortLeaves)
                                                .map((leaf, index) => (
                                                    <DraggableListItem
                                                        leaf={leaf}
                                                        shared={false}
                                                        index={index}
                                                        path={'/'}
                                                        handleLeafContextClick={handleLeafContextClick}
                                                        handleRightClick={handleRightClick}
                                                        key={leaf._id}
                                                        dragInProgress={dragInProgress}
                                                        combineTarget={combineTarget}
                                                        toggleMarkDone={toggleMarkDone}
                                                        currentLeaf={currentLeaf}
                                                        renderLinks={true}
                                                        selectedTaskIds={selectedTaskIds}
                                                        setSelectedTaskIds={setSelectedTaskIds}
                                                        leaves={leaves}
                                                    />
                                                ))}
                                            {provided.placeholder}
                                        </Box>
                                    </List>
                                )}
                        </Droppable>}
                    {anchorLeaf &&
                        <ListItemContextMenu
                            contextMenuOpen={contextMenuOpen}
                            setContextMenuOpen={setContextMenuOpen}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            anchorLeaf={anchorLeaf}
                            setAnchorLeaf={setAnchorLeaf}
                            contextMenuPosition={contextMenuPosition}
                            setContextMenuPosition={setContextMenuPosition}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            token={token}
                            currentLeaf={currentLeaf}
                            toggleMarkDone={toggleMarkDone}
                            path={'/'}
                            selectedTaskIds={selectedTaskIds}
                            setSelectedTaskIds={setSelectedTaskIds}
                        />
                    }
                    <TextInput
                        currentLeaf={currentLeaf}
                        leaves={leaves}
                        setLeaves={setLeaves}
                        token={token}
                    />
                    <Box mt={4} >
                        <UndoSnackbar
                            undo={() => {
                                addLeaf(leafPendingDeletion);
                                setLeafPendingDeletion(null);
                            }}
                            open={snackBarOpen}
                            setOpen={setSnackBarOpen} />
                    </Box>
                </>
            }
        </>
    );
}

export default Leaf;