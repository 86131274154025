const validUrl = (str) => {
    // test cases are here: regexr.com/6m210
    return /^((?:\w+:)?\/\/([^\s]+\.\S{2}|localhost[?\d]*|tel:\S*)|tel:|sms:|mailto:)\S*$/.test(str);
}

const validImageUrl = str => {
    return /(jpg|jpeg|gif|png|webp)((\?.*)$|$)/.test(str);
}

const validVideoUrl = str => {
    return /(mov|m4v|mp4|avi|webm)((\?.*)$|$)/.test(str);
}

const youtubeUrl = str => {
    // returns false for channel links and true for video links or short share links
    return /(^(https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$)/.test(str) && (str.indexOf("v=") !== -1 || str.indexOf("youtu.be") !== -1);
}

const getYoutubeVideoId = str => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = str.match(regExp);
    return (match && match[7].length === 11)? match[7] : false;
}

const validEmail = str => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);
}

const cleanUrl = url => {
    return url.replace(/(^\w+:|^)\/\//, '');
}

export { validUrl, validImageUrl, validVideoUrl, youtubeUrl, getYoutubeVideoId, validEmail, cleanUrl };