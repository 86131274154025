exports.updatePwaAssets = ({ faviconUrl, appleTouchIconUrl, appName, appBackground }) => {
    if (faviconUrl) {
        const favicon = document.getElementById("favicon");
        favicon.href = faviconUrl;
    }
    if (appleTouchIconUrl) {
        const appleTouchIcon = document.getElementById("apple-touch-icon");
        appleTouchIcon.href = appleTouchIconUrl;
        manifestJSON.icons[0].src = appleTouchIconUrl;
    }
    const appleAppName = document.getElementById("apple-mobile-web-app-title");
    if (appName) {
        appleAppName.content = appName;
        document.title = appName;
        manifestJSON.name = appName;
    } else {
        appleAppName.content = document.title;
    }

    const statusBar = document.getElementById("apple-mobile-web-app-status-bar-style");
    const themeColor = document.getElementById("theme-color");
    const backgroundColor = document.getElementById("background-color");
    if (appBackground) {
        statusBar.content = 'black-translucent';
        themeColor.content = appBackground;
        backgroundColor.content = appBackground;
    } else {
        themeColor.content = null;
        backgroundColor.content = null;
        statusBar.content = 'default';
    }

    if (appName) {
        const stringManifest = JSON.stringify(manifestJSON);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
    }
}

let manifestJSON = {
    // "name": "Ginkgo",
    "icons": [
        // {
        //     "src": "/android-chrome-192x192.png",
        //     "sizes": "192x192",
        //     "type": "image/png"
        // },
        // {
        //     "src": "/android-chrome-512x512.png",
        //     "sizes": "512x512",
        //     "type": "image/png"
        // },
        {
            "src": "/apple-touch-icon.png",
            "sizes": "180x180",
            "type": "image/png"
        }
    ],
    "display": "standalone"
}

exports.isPwaInstalled = () => {
    // For iOS
    if (window.navigator.standalone) return true

    // For Android
    if (window.matchMedia('(display-mode: standalone)').matches) return true

    // If neither is true, it's not installed
    return false
}