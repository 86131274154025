
import { Link as NavLink } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';
// material UI
import { 
  Breadcrumbs,
  Box,
  Typography,
  Link
 } from '@mui/material/';
import Skeleton from '@mui/material/Skeleton';
// styles
import { noOverflow } from '../styles/main';

const BreadcrumbNav = ({ currentLeaf, shared, linkShareId, secureShare, secureShareToken }) => {
  return (
    <Box mt={4}>
      {currentLeaf ?
        <Breadcrumbs aria-label="breadcrumb" component='nav' style={noOverflow}>
          {currentLeaf.path && currentLeaf.path.map((leaf, index) => {
            let to = '';
            // this mutates the path array
            currentLeaf.path.sort((a, b) => b.order - a.order);
            if (shared && linkShareId) {
              to = secureShare ? '/secure-share/' + secureShareToken : '/share/' + linkShareId;
            }
            if (index !== 0) {
              to += '/' + leaf._id
            }

            return (
              <Droppable droppableId={leaf._id} key={to}>
                {(provided, snapshot) => (
                  <Link
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    component={NavLink} color="inherit"
                    to={to}
                    style={{
                      ...noOverflow,
                      background: snapshot.isDraggingOver ? "#D8F2E0" : "none"
                    }}
                  >
                    {leaf.displayAs ? leaf.displayAs : leaf.content}
                  </Link>
                )}
              </Droppable>
            );
          })}
          {currentLeaf &&
            <Typography color="textPrimary" key={currentLeaf._id}>
              {currentLeaf.displayAs ? currentLeaf.displayAs : currentLeaf.content}
            </Typography>
          }
        </Breadcrumbs>
        :
        <Breadcrumbs component='nav' style={noOverflow}>
          <Skeleton width='160px' animation="wave" />
        </Breadcrumbs>
      }
    </Box>

  )
}

export default BreadcrumbNav;