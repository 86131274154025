import { useState } from 'react';
import {
    Menu, MenuItem, ListItemIcon, List,
    ListItem, IconButton, ListItemSecondaryAction,
    FormControlLabel, Switch, Button, Chip, Select,
    Typography, Checkbox
} from "@mui/material";
import { noOverflow } from '../styles/main';
import {
    UnfoldLess, UnfoldMore,
    Folder, Logout, CheckCircle, FolderOpen, Clear,
    Settings, People, Link, FormatListNumberedRtl, VisibilityOff
} from '@mui/icons-material';
import { updateLeaf, updateSharedLeaf, updateLinkShareActive, updateDeepSharedLeaf, updateLinkShareDetails } from '../util/leaf-functions';

const LeafSettingsMenu = ({ currentLeaf, setCurrentLeaf, token, setToken, linkShareId, linkShare, setLinkShare, deepShared }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [copied, setCopied] = useState(false);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // experiment with switches, needs cleanup
    const handleLinkSwitch = event => {
        updateLinkShareActive(currentLeaf._id, token, event.target.checked)
            .then(response => response.json())
            .then(sharelink => {
                if (sharelink !== null && sharelink !== undefined) {
                    setLinkShare(sharelink);
                } else {
                    setLinkShare(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLinkShare(false);
            })
    };

    const handleInstallPromptSwitch = event => {
        updateLinkShareDetails({
            linkshareId: linkShare._id,
            token: token,
            showInstallPrompt: event.target.checked
        })
            .then(response => response.json())
            .then(sharelink => {
                if (sharelink !== null && sharelink !== undefined) {
                    setLinkShare(sharelink);
                } else {
                    setLinkShare(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLinkShare(false);
            })
    };

    const handleLinkPermissionChange = event => {
        let editable = event.target.value.indexOf('edit') !== -1;
        let shareDeep = event.target.value.indexOf('children') !== -1;
        updateLinkShareDetails({
            linkshareId: linkShare._id,
            token: token,
            editable: editable,
            shareDeep: shareDeep
        })
            .then(response => response.json())
            .then(sharelink => {
                if (sharelink) {
                    setLinkShare(sharelink);
                } else {
                    setLinkShare(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLinkShare(false);
            })
    }

    const copyToClipboard = content => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(content);
            setCopied(true);
            setTimeout(() => setCopied(false), 700);
        }
    }

    let updatePromise = leaf => {
        return linkShareId ?
            deepShared ?
                updateDeepSharedLeaf(leaf._id, leaf, linkShareId) :
                updateSharedLeaf(leaf._id, leaf, linkShareId) :
            updateLeaf(leaf._id, leaf, token)
    }

    const toggleDense = () => {
        if (currentLeaf) {
            let rollback = { ...currentLeaf };
            let d = currentLeaf.dense;
            if (!d) {
                d = true;
            } else {
                d = !d;
            }
            setCurrentLeaf({ ...currentLeaf, dense: d });
            updatePromise({ ...currentLeaf, dense: d })
                .then(response => {
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request");
                    }
                })
                .catch(err => {
                    setCurrentLeaf(rollback);
                })
        }
    }

    const toggleChecklist = () => {
        if (currentLeaf) {
            let rollback = { ...currentLeaf };
            let t = currentLeaf.type;
            if (t === undefined || t === null) {
                t = 'checklist';
            } else {
                t = null;
            }
            setCurrentLeaf({ ...currentLeaf, type: t });
            updatePromise({ ...currentLeaf, type: t })
                .then(response => {
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request");
                    }
                })
                .catch(err => {
                    setCurrentLeaf(rollback);
                })
        }
    }

    const toggleCombine = () => {
        if (currentLeaf) {
            let rollback = { ...currentLeaf };
            let c = currentLeaf.combine;
            if (c === undefined) {
                c = false;
            } else {
                c = !c;
            }
            setCurrentLeaf({ ...currentLeaf, combine: c });
            updatePromise({ ...currentLeaf, combine: c })
                .then(response => {
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request");
                    }
                })
                .catch(err => {
                    setCurrentLeaf(rollback);
                })
        }
    }

    const toggleChildCounts = () => {
        if (currentLeaf) {
            let rollback = { ...currentLeaf };
            let h = currentLeaf.hideChildCounts;
            if (!h) {
                h = true;
            } else {
                h = null;
            }
            setCurrentLeaf({ ...currentLeaf, hideChildCounts: h });
            return updatePromise({ ...currentLeaf, hideChildCounts: h })
                .then(response => {
                    if (response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request");
                    }
                })
                .catch(err => {
                    setCurrentLeaf(rollback);
                })
        }
    }

    const shareableLink = (content, includeProtocol) => {
        let userFriendlyHashRoute = content.length > 33 ? `${content.substring(0, 30)}` : content;
        userFriendlyHashRoute = userFriendlyHashRoute.replace(/\s/g, '_');
        return includeProtocol ?
            `${window.location.protocol}//${window.location.host}/share/${linkShare._id}#${userFriendlyHashRoute}` :
            `${window.location.host}/share/${linkShare._id}#${userFriendlyHashRoute}`;
    }

    const logOut = () => {
        localStorage.removeItem('token', null);
        setToken(null);
    }

    return <>
        {((token && linkShareId === undefined) || (!currentLeaf?.locked && linkShareId !== undefined && currentLeaf.editable === true)) &&
            <List>
                <ListItem>
                    <ListItemSecondaryAction>
                        {token && linkShareId === undefined &&
                            <IconButton
                                data-testid="share-menu-button"
                                id="share-menu"
                                edge="end"
                                aria-label="share leaf"
                                onClick={handleClick}
                                disabled={!currentLeaf}
                                size="large">
                                <People />
                            </IconButton>
                        }
                        <IconButton
                            data-testid="settings-menu-button"
                            id="settings-menu"
                            edge="end"
                            aria-label="leaf settings"
                            onClick={handleClick}
                            disabled={!currentLeaf}
                            size="large">
                            <Settings />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        }
        {currentLeaf &&
            <>
                <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && anchorEl.id === 'share-menu')}
                    onClose={handleClose}
                    sx={{ minWidth: "450px", maxWidth: "500px" }}
                    componentsProps={{ backdrop: { "data-testid": "share-menu-backdrop" } }}
                >
                    <ListItem
                        sx={{ minWidth: "450px" }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    data-testid="share-menu-switch"
                                    checked={Boolean(linkShare && linkShare.active)}
                                    onChange={handleLinkSwitch}
                                    color="primary"
                                />
                            }
                            label="Share this leaf"
                        />
                        <ListItemSecondaryAction>
                            <Button
                                disabled={linkShare && linkShare.active ? false : true}
                                onClick={() => copyToClipboard(shareableLink(currentLeaf.content, true))}
                            >
                                {copied ?
                                    'Copied'
                                    :
                                    'Copy link'
                                }
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {linkShare && linkShare.active &&
                        <ListItem>
                            <Chip
                                data-testid="share-menu-link-chip"
                                style={noOverflow}
                                onClick={() => copyToClipboard(shareableLink(currentLeaf.content, true))}
                                variant="outlined"
                                icon={<Link />}
                                label={shareableLink(currentLeaf.content, false)}
                            />
                        </ListItem>
                    }
                    {linkShare && linkShare.active &&
                        <ListItem>
                            {/* need to show space after this line */}
                            Anyone with this link can&nbsp;
                            <Select
                                size="small"
                                data-testid="share-menu-permission-select"
                                value={linkShare && linkShare.editable ?
                                    linkShare.shareDeep ?
                                        'edit + children' :
                                        'edit'
                                    :
                                    linkShare.shareDeep ?
                                        'view + children' :
                                        'view'
                                }
                                onChange={handleLinkPermissionChange}
                            >
                                <MenuItem value='view' >view this leaf</MenuItem>
                                <MenuItem value='edit' >edit this leaf</MenuItem>
                                <MenuItem value='view + children' >view this leaf and children</MenuItem>
                                <MenuItem value='edit + children' >edit this leaf and children</MenuItem>
                            </Select>
                        </ListItem>
                    }
                    {linkShare && linkShare.active &&
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // size='small'
                                        data-testid="share-menu-prompt-install-switch"
                                        checked={Boolean(linkShare?.showInstallPrompt)}
                                        onChange={handleInstallPromptSwitch}
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="body2">Prompt recipients to install mobile app</Typography>}
                            >
                            </FormControlLabel>
                        </ListItem>
                    }
                </Menu>
                <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && anchorEl.id === 'settings-menu')}
                    onClose={handleClose}
                >
                    {!currentLeaf.locked &&
                        <MenuItem
                            onClick={toggleDense}
                            style={{ minWidth: '260px' }}
                        >
                            {currentLeaf.dense ?
                                <>
                                    <ListItemIcon>
                                        <UnfoldMore />
                                    </ListItemIcon>
                                    Expand layout
                                </>
                                :
                                <>
                                    <ListItemIcon>
                                        <UnfoldLess />
                                    </ListItemIcon>
                                    Compress layout
                                </>
                            }
                        </MenuItem>
                    }
                    {!currentLeaf.locked &&
                        <MenuItem onClick={toggleChecklist} >
                            {currentLeaf.type === 'checklist' ?
                                // 'Disable checklist mode' : 'Enable checklist mode'
                                <>
                                    <ListItemIcon>
                                        <Clear />
                                    </ListItemIcon>
                                    Disable checklist mode
                                </>
                                :
                                <>
                                    <ListItemIcon>
                                        <CheckCircle />
                                    </ListItemIcon>
                                    Make checklist
                                </>
                            }
                        </MenuItem>
                    }
                    {/* MenuItems must appear directly under Menu to be keyboard navigable so these 2 are separate even though they depend on the same condition to be shown */ }
                    {(linkShareId === undefined && !currentLeaf.locked) &&
                        <MenuItem onClick={toggleCombine} >
                            {currentLeaf.combine ?
                                <>
                                    <ListItemIcon>
                                        <FolderOpen />
                                    </ListItemIcon>
                                    Disable nesting
                                </>
                                :
                                <>
                                    <ListItemIcon>
                                        <Folder />
                                    </ListItemIcon>
                                    Enable nesting
                                </>
                            }
                        </MenuItem>
                    }
                    {(linkShareId === undefined && !currentLeaf.locked) &&
                        <MenuItem onClick={toggleChildCounts} >
                            {currentLeaf.hideChildCounts ?
                                <>
                                    <ListItemIcon>
                                        <FormatListNumberedRtl />
                                    </ListItemIcon>
                                    Show child counts
                                </>
                                :
                                <>
                                    <ListItemIcon>
                                        <VisibilityOff />
                                    </ListItemIcon>
                                    Hide child counts
                                </>
                            }
                        </MenuItem>
                    }
                    {token && !linkShareId &&
                        <MenuItem data-testid="logout-menu-item" onClick={logOut} >
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            Log out
                        </MenuItem>
                    }
                </Menu>
            </>
        }
    </>;
}

export default LeafSettingsMenu;