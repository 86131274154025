let normalWidth = {
    maxWidth: 'calc(100% - 35px)'
}

let fullWidth = {
    maxWidth: '100%'
}

let doneStyle = {
    color: 'DarkSeaGreen'
    // textDecoration: 'line-through',
};

let loadingStyle = {
    color: 'rgba(0, 0, 0, 0.44)',
}

let checklistNormalWidth = {
    maxWidth: 'calc(100% - 100px)'
}

let makeStyle = ({ dragInProgress, snapshot, leaf, selectedTaskIds, shared, currentLeaf }) => {
    let style = { ...normalWidth };
    if (leaf.disabled || (dragInProgress && selectedTaskIds && selectedTaskIds.includes(leaf._id) && !snapshot.isDragging))
        style = { ...loadingStyle };
    if (leaf.done)
        style = { ...style, ...doneStyle }
    if (leaf.locked && shared)
        style = { ...style, ...fullWidth }
    if (!currentLeaf.type === "checklist")
        style = { ...style, ...checklistNormalWidth }

    return style;
}

export { makeStyle }