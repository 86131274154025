import { Badge } from '@mui/material';

export default function CenteredBadge(props) {
    return (
        <Badge
            {...props}
            sx={{
                '& .MuiBadge-badge': {
                    right: 13,
                    top: 13,
                    padding: '0 6px',
                }
            }}
        />
    )
}