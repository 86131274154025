import React, { useEffect, useState } from 'react';
// third party libs
import { useParams } from "react-router-dom";
// material UI
import { List, Box } from "@mui/material";
//util
import { updatePwaAssets } from '../util/pwa-utils';
// my components
import BreadcrumbNav from "./breadcrumbNav";
import ReadOnlyLeaf from "./readOnlyLeaf";
import EditableLeaf from "./editableLeaf";
import DraggableListItem from "./list-item/draggableListItem";
import LeafSettingsMenu from "./leafSettingsMenu";
import NotFound from "./404";

// the two versions of linkShareId (and linkshareId) should be cleaned up here
const DeepSharedLeaf = ({ leaves, setLeaves, setLinkshareId, currentLeaf, 
    setCurrentLeaf, token, linkShareId, setDeepShared, combineTarget, secureShare
}) => {
    let { linkshareId, leafId, secureShareToken } = useParams();
    const [loading, setLoading] = useState(true);

    // get settings for current leaf
    useEffect(() => {
        // Are the below 2 lines needed?
        if (!linkshareId) { 
            linkshareId = linkShareId;
        } else if (linkshareId !== null) { 
            setLinkshareId(linkshareId);
        }
  
        setDeepShared(true);
        setLoading(true);
        setCurrentLeaf(null);
        fetch('/api/share/deep/leaves/' + leafId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Link ' + linkshareId
            }
        })
            .then(response => response.json())
            .then(leaf => {
                document.title = leaf.currentLeaf.content;
                setLeaves(leaf.leaves);
                setCurrentLeaf(leaf.currentLeaf);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            })
    }, [linkshareId, setLeaves, leafId]);

    useEffect(() => {
        updatePwaAssets({
            faviconUrl: currentLeaf?.faviconUrl,
            appleTouchIconUrl: currentLeaf?.appleTouchIconUrl,
            appName: currentLeaf?.appName,
            appBackground: currentLeaf?.pageBackground
        })
    }, [currentLeaf])

    let path = secureShare ? `/secure-share/${secureShareToken}/` : `/share/${linkshareId}/`;

    return (
        <>
            {!currentLeaf || !leaves ?
                <>
                    {loading ?
                        <>
                            <BreadcrumbNav />
                            <LeafSettingsMenu />
                            <Box mt={2}>
                                <List>
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                    <DraggableListItem />
                                </List>
                            </Box>
                        </>
                        :
                        <NotFound />
                    }
                </>
                :
                <>
                    <BreadcrumbNav
                        currentLeaf={currentLeaf}
                        linkShareId={linkShareId}
                        shared={true}
                        secureShare={secureShare} 
                        secureShareToken={secureShareToken} 
                    />
                    {currentLeaf.editable ?
                        // editableLeaf needs to be different from leaf in that there's no combining, no leaf links, and fewer menu options
                        <EditableLeaf
                            currentLeaf={currentLeaf}
                            setCurrentLeaf={setCurrentLeaf}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            linkShareId={linkShareId}
                            // linkShare={currentLeaf.linkShare}
                            token={token}
                            path={path}
                            deepShared={true}
                            renderLinks={true}
                            combineTarget={combineTarget}
                        />
                        :
                        <ReadOnlyLeaf
                            linkShareId={linkShareId}
                            currentLeaf={currentLeaf}
                            setCurrentLeaf={setCurrentLeaf}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            path={path}
                            renderLinks={true}
                        />
                    }
                </>
            }
        </>
    )

}

export default DeepSharedLeaf;