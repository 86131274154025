
import React, { useState, useRef } from 'react';
// material UI
import { Typography, Button, TextField, Box, Link } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Link as NavLink } from 'react-router-dom';
// my functions
import { validEmail } from '../util/url-checker';

const Login = ({ setToken }) => {
    const [pageType, setPageType] = useState("sign-up");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const passwordInput = useRef(null);

    const handleInputChange = (event) => {
        if (event.target.id === 'email-input')
            setEmail(event.target.value);
        else
            setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        if (event.key !== 'Enter') {
            return;
        } else {
            if (event.target.id === 'email-input') {
                passwordInput.current.focus();
            } else {
                pageType === 'login' ? getToken() : signUp();
            }
            // event.preventDefault();
        }
    };

    const handlePageType = (event, type) => {
        if (type !== null) {
            setPageType(type);
        }
    };

    const invalidForm = (validatePassword) => {
        let invalidForm = false;
        if (!validEmail(email)) {
            setEmailError("Enter a valid email address");
            invalidForm = true;
        } else {
            setEmailError(null);
        }
        if (validatePassword && (!password || password.length < 10)) {
            setPasswordError("Enter password with at least 10 characters");
            invalidForm = true;
        } else {
            setPasswordError(null);
        }
        return invalidForm;
    }

    const getToken = () => {
        if (invalidForm(true))
            return;
        setButtonDisabled(true);
        let credentials = {
            email: email,
            password: password
        };
        credentials = JSON.stringify(credentials);
        fetch('/api/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: credentials
        })
            .then(response => {
                if (response.status === 401) {
                    setEmailError('Incorrect email or password');
                    setPasswordError('Password');
                    setButtonDisabled(false);
                } else {
                    response.json()
                        .then(tokenResponse => {
                            let token = tokenResponse.accessToken;
                            console.log('Success getting token.');
                            setToken(token);
                            localStorage.setItem('token', token);
                        })
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setButtonDisabled(false);
            })
    }

    const sendLoginLink = () => {
        if (invalidForm(false))
            return;
        setButtonDisabled(true);
        let credentials = {
            email: email
        };
        credentials = JSON.stringify(credentials);
        fetch('/api/email-token', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: credentials
        })
            .then(response => {
                if (response.status === 401) {
                    setEmailError('No user with that email found');
                } else {
                    alert("Login link sent to " + email);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
        setButtonDisabled(false);
    }

    const signUp = () => {
        if (invalidForm(true))
            return;
        setButtonDisabled(true);
        let credentials = {
            email: email,
            password: password
        };
        credentials = JSON.stringify(credentials);
        fetch('/api/sign-up', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: credentials
        })
            .then(response => {
                if (response.status === 409) {
                    setEmailError('A user with this email already exists');
                } else {
                    return getToken();
                }
                setButtonDisabled(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setButtonDisabled(false);
            })
    }

    return (
        <Box mt={2}>
            <Typography variant='h6'>
                🌱 Welcome to Ginkgo!
            </Typography>
            <Typography color="textSecondary">
                Ginkgo is a space for organizing your thoughts and to-dos
            </Typography>
            <Box mt={2}>
                <ToggleButtonGroup
                    exclusive
                    size='small'
                    value={pageType}
                    onChange={handlePageType}
                    aria-label="text formatting"
                >
                    <ToggleButton data-testid='get-started-toggle' value="sign-up" aria-label="bold">
                        Get started
                    </ToggleButton>
                    <ToggleButton data-testid='login-toggle' value="login" aria-label="header">
                        Log in
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box mt={2}>
                <TextField
                    data-testid='email-input'
                    id="email-input"
                    value={email}
                    label={emailError !== null ? emailError : 'Email'}
                    error={emailError !== null}
                    variant="filled"
                    fullWidth
                    onChange={handleInputChange}
                    onKeyPress={handleSubmit}
                    autoFocus
                />
            </Box>
            <Box mt={2}>
                <TextField
                    data-testid='password-input'
                    id="password-input"
                    // label={pageType === 'login' ? "Password" : "Create password"}
                    value={password}
                    label={passwordError !== null ? passwordError : pageType === 'login' ? "Password" : "Create password (at least 10 characters)"}
                    error={passwordError !== null}
                    type="password"
                    variant="filled"
                    fullWidth
                    onChange={handleInputChange}
                    onKeyPress={handleSubmit}
                    inputRef={passwordInput}
                />
            </Box>
            <Box mt={2}>
                {pageType === 'login' ?
                    <Button data-testid='login-button' variant="contained" disabled={buttonDisabled} color="primary" onClick={() => password ? getToken() : sendLoginLink()}>
                        {password ? 'Log in' : 'Send login link'}
                    </Button> :
                    <Button data-testid='get-started-button' variant="contained" disabled={buttonDisabled} color="primary" onClick={signUp}>Get started</Button>
                }
                {pageType === 'login' &&
                    <Box mt={2}>
                        <Link component={NavLink} to={"/request-reset" + (email ? `/${email}` : '')} >Forgot password?</Link>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default Login;