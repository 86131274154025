import { tabId } from './tab-id';

const addLeaf = (newLeaf, token) => {
    return fetch('/api/leaves', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(newLeaf)
    })
}

const uploadFile = (file, leaf, token) => {
    const formData = new FormData();
    formData.append('parentId', leaf.parentId)
    formData.append('rank', leaf.rank)
    // formData.append('parentId', leaf.parentId)
    formData.append('formats', leaf.formats)
    formData.append('_id', leaf._id)
    formData.append('file', file);
    return fetch('/api/upload', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: formData
    })
}

const addSharedLeaf = (newLeaf, linkshareId) => {
    return fetch('/api/share/leaves', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(newLeaf)
    })
}

const uploadFileShared = (file, leaf, linkShareId) => {
    const formData = new FormData();
    formData.append('rank', leaf.rank)
    formData.append('parentId', leaf.parentId)
    formData.append('formats', leaf.formats)
    formData.append('_id', leaf._id)
    formData.append('file', file);
    return fetch('/api/share/upload', {
        method: 'POST',
        headers: {
            'Authorization': 'Link ' + linkShareId
        },
        body: formData
    })
}

const addDeepSharedLeaf = (newLeaf, linkshareId) => {
    return fetch('/api/share/deep/leaves', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(newLeaf)
    })
}

const uploadFileDeepShared = (file, leaf, linkShareId) => {
    const formData = new FormData();
    formData.append('rank', leaf.rank)
    formData.append('parentId', leaf.parentId)
    formData.append('formats', leaf.formats)
    formData.append('_id', leaf._id)
    formData.append('file', file);
    return fetch('/api/share/deep/upload', {
        method: 'POST',
        headers: {
            'Authorization': 'Link ' + linkShareId
        },
        body: formData
    })
}

const updateLeaf = (id, leaf, token) => {
    if (!id) {
        console.log("No id passed to updateLeaf()");
        return;
    }
    leaf.tabId = tabId;
    return fetch('/api/leaves/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(leaf)
    })
}

const scheduleReminder = (id, remindAt, token) => {
    if (!id) {
        console.log("No id passed to scheduleReminder()");
        return;
    }
    let body = { 
        leafId: id,
        remindAt: remindAt
    }
    return fetch('/api/reminder', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
}

const updateLeafLock = (id, locked, token) => {
    if (!id) {
        console.log("No id passed to updateLeaf()");
        return;
    }
    let body = { 
        locked: locked,
        tabId: tabId
    }
    return fetch('/api/leaves/lock/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
}

const updateManyLeaves = (ids, newLeaf, updateType, token) => {
    if (!ids) {
        throw new Error("No ids passed to updateLeaf()");
    }

    let update = { 
        ids: ids,
        parentId: newLeaf.parentId
     };

    if(updateType === "formats") {
        update.formats = newLeaf.formats;
    } else if(updateType === "done") {
        update.done = newLeaf.done;
    } else {
        throw new Error("Invalid updateType passed to updateLeaf()");
    }
    
    return fetch('/api/leaves/bulk/update', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(update)
    })
}

const updateSharedLeaf = (leafId, leaf, linkshareId) => {
    if (!leafId || !leaf || !linkshareId) {
        return Promise.resolve('leafId or linkshareId missing in call to updateSharedLeaf()');
    }
    leaf.tabId = tabId;
    return fetch('/api/share/leaves/' + leafId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(leaf)
    })
}

const updateDeepSharedLeaf = (leafId, leaf, linkshareId) => {
    if (!leafId || !leaf || !linkshareId) {
        return Promise.resolve('leafId or linkshareId missing in call to updateDeepSharedLeaf()');
    }
    leaf.tabId = tabId;
    return fetch('/api/share/deep/leaves/' + leafId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(leaf)
    })
}

const duplicateLeaf = (leaf, token) => {
    let body = {
        parentId: leaf.parentId,
        tabId: tabId
    }

    return fetch('/api/leaves/duplicate/' + leaf._id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
}

const deleteLeaf = (id, token) => {
    return fetch('/api/leaves/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

const deleteManyLeaves = (ids, token) => {
    let body = {
        ids: ids
    };
    return fetch('/api/leaves/bulk', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
}

const deleteDeepSharedLeafChild = (id, linkshareId) => {
    return fetch('/api/share/deep/leaves/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        }
    })
}

const updateRank = (moverId, aboveId, belowId, token) => {
    let body = {
        moverId: moverId,
        aboveId: aboveId,
        belowId: belowId
    };

    return fetch('/api/leaves/rank', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .catch((error) => {
            console.error('Error:', error);
        })
};

const updateManyLeavesRanks = (moverIds, aboveId, belowId, token) => {
    let body = {
        moverIds: moverIds,
        aboveId: aboveId,
        belowId: belowId
    };
    return fetch('/api/leaves/bulk/rank', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .catch((error) => {
            console.error('Error:', error);
        })
};

const updateSharedRank = (moverId, aboveId, belowId, linkshareId) => {
    let body = {
        moverId: moverId,
        aboveId: aboveId,
        belowId: belowId
    };

    return fetch('/api/share/leaves/rank', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(body)
    })
        .catch((error) => {
            console.error('Error:', error);
        })
};

const updateDeepSharedRank = (moverId, aboveId, belowId, linkshareId) => {
    let body = {
        moverId: moverId,
        aboveId: aboveId,
        belowId: belowId
    };

    return fetch('/api/share/deep/leaves/rank', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(body)
    })
        .catch((error) => {
            console.error('Error:', error);
        })
};

const setLeafParent = (leafId, parentId, originalParentId, token) => {
    if (!leafId || !parentId || !originalParentId) {
        console.error("leafId or parentId missing or null in call to setLeafParent()");
        return Promise.resolve(Error);
    }
    let body = {
        parentId: parentId,
        originalParentId: originalParentId,
        tabId: tabId
    };
    return fetch('/api/leaves/parent/' + leafId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .catch(err => {
            console.error('Error:', err);
            return err;
        })
}

const updateManyLeavesParent = (ids, parentId, originalParentId, token) => {
    if (!ids || !parentId || !originalParentId) {
        console.error("leafId or parentId missing or null in call to updateManyLeavesParent()");
        return;
    }
    let body = {
        ids: ids,
        parentId: parentId,
        originalParentId: originalParentId,
        tabId: tabId
    };
    return fetch('/api/leaves/bulk/parent', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .catch(err => {
            console.error('Error:', err);
            return err;
        })
}

const setDeepSharedLeafParent = (leafId, newParentId, originalParentId, linkshareId) => {
    if (!leafId || !newParentId || !originalParentId) {
        console.error("leafId or newParentId missing or null in call to setDeepSharedLeafParent()");
        return;
    }
    let body = {
        parentId: newParentId,
        originalParentId: originalParentId,
        tabId: tabId
    };
    return fetch('/api/share/deep/leaves/parent/' + leafId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Link ' + linkshareId
        },
        body: JSON.stringify(body)
    })
        .catch(err => {
            console.error('Error:', err);
            return err;
        })
}

const updateLinkShareActive = (leafId, token, activate) => {
    if (!leafId) {
        return;
    }
    let action = activate ? '/activate' : '/deactivate';
    return fetch('/api/leaves/share/' + leafId + action, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
        .catch(err => {
            console.error('Error:', err);
            return err;
        })
}

const updateLinkShareDetails = ({ linkshareId, token, editable, shareDeep, showInstallPrompt }) => {
    if (!linkshareId) {
        return;
    }
    const update = {}
    if(editable !== undefined)
        update.editable = editable;
    if(shareDeep !== undefined)
        update.shareDeep = shareDeep;
    if(showInstallPrompt === false)
        showInstallPrompt = null;
    update.showInstallPrompt = showInstallPrompt;

    return fetch('/api/leaves/share/' + linkshareId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(update)
    })
        .catch(err => {
            console.error('Error:', err);
            return err;
        })
}

export {
    addLeaf, addSharedLeaf, addDeepSharedLeaf, uploadFile, uploadFileShared, uploadFileDeepShared, setLeafParent, updateManyLeavesParent, setDeepSharedLeafParent, updateLeaf,
    updateLeafLock, updateManyLeaves, duplicateLeaf, deleteLeaf, deleteManyLeaves, deleteDeepSharedLeafChild, updateRank, updateManyLeavesRanks, updateSharedRank,
    updateDeepSharedRank, updateLinkShareActive, updateLinkShareDetails, updateSharedLeaf,
    updateDeepSharedLeaf, scheduleReminder
};