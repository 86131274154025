import React, { useState, useEffect } from "react";
import { Alert } from '@mui/material';

const OfflineWarning = () => {
    let [online, setOnline] = useState(navigator.onLine);

    // Register the event listeners
    useEffect(() => {
        window.addEventListener('offline', () => setOnline(false));
        window.addEventListener('online', () => setOnline(true));

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', () => setOnline(false));
            window.removeEventListener('online', () => setOnline(true));
        }
    }, []);

    return (!online && <Alert severity="warning">You're offline</Alert>)
};

export default OfflineWarning;
