
const LeafReducer = {
    addLeaf: (leaves, leaf) => {
        return [...leaves, leaf];
    },

    addManyLeaves: (leaves, newLeaves) => {
        let newLeafIds = newLeaves.map(l => l._id);
        let existingLeaves = leaves.filter(l => !newLeafIds.includes(l._id))
        return [...existingLeaves, ...newLeaves];
    },

    updateLeaf: (leaves, leaf) => {
        let newLeaves = JSON.parse(JSON.stringify(leaves));
        let index = newLeaves.findIndex(l => l._id === leaf._id);
        // if(index !== -1) newLeaves[index] = leaf;
        newLeaves.splice(index, 1);
        newLeaves.push(leaf);
        return newLeaves;
    },

    updateLeafRank: (leaves, leaf) => {
        let newLeaves = JSON.parse(JSON.stringify(leaves));
        let index = newLeaves.findIndex(l => l._id === leaf._id);
        if (index !== -1) newLeaves[index].rank = leaf.rank;
        return newLeaves;
    },

    updateManyLeavesRanks: (leaves, updates) => {
        let newLeaves = JSON.parse(JSON.stringify(leaves));
        updates.forEach(leaf => {
            let index = newLeaves.findIndex(l => l._id === leaf._id);
            if (index !== -1) newLeaves[index].rank = leaf.rank;
        })
        return newLeaves;
    },

    updateManyLeaves: (leaves, updates) => {
        let newLeaves = JSON.parse(JSON.stringify(leaves));
        updates.forEach(leaf => {
            let index = newLeaves.findIndex(l => l._id === leaf._id);
            if (index !== -1) newLeaves[index] = leaf;
        })
        return newLeaves;
    },

    removeLeaf: (leaves, leaf) => {
        return leaves.filter(l => l._id !== leaf._id)
    },

    removeManyLeaves: (leaves, ids) => {
        return leaves.filter(l => !ids.includes(l._id))
    },

    updateManyLeafFormats: (leaves, update, ids) => {
        let newLeaves = [...leaves];
        newLeaves = newLeaves.map(l => {
            if (ids.includes(l._id)) {
                l = { ...l, ...update };
            }
            return l;
        });
        return newLeaves;
    },

    updateManyLeafFormatsIndividually: (leaves, updates, ids) => {
        let newLeaves = [...leaves];
        newLeaves = newLeaves.map(l => {
            if (ids.includes(l._id)) {
                let updatedLeaf = updates.find(leaf => leaf._id === l._id)
                l = { ...l, formats: updatedLeaf.formats, done: updatedLeaf.done };
            }
            return l;
        });
        return newLeaves;
    }
}

export default LeafReducer;