import React, { } from 'react';
// material UI
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
// icons
import CloseIcon from '@mui/icons-material/Close';


const UndoSnackbar = ({ undo, open, setOpen }) => {

    const handleClose = (event, reason, shouldUndo) => {
        if (reason === 'clickaway') {
          return;
        }
        if (shouldUndo){
            shouldUndo();
        }
        setOpen(false);
      };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message="Leaf deleted"
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" data-tag='undo' onClick={(event, reason) => handleClose(event, reason, undo)}>
                        UNDO
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

export default UndoSnackbar;