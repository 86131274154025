// material UI
import {
    ListItem,
    ListItemText
} from '@mui/material';
// styles
import { makeStyle } from '../../styles/listItemText';
import { makeListItemStyle } from '../../styles/listItem';
// my components
import ListItemContent from './listItemContent';
import ContextMenuButton from './contextMenuButton';
import { browserShare } from '../../util/browser-utils';

export default function GinkgoShareButton({
    leaf, onClick, onContextMenu, handleLeafContextClick,
    combineTarget, currentLeaf,
    selectedTaskIds, dragInProgress, shared, provided, snapshot
}) {

    let hideContextMenu = shared && leaf.locked;

    return (
        <ListItem
            data-testid="list-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            disabled={leaf.disabled || false}
            selected={selectedTaskIds && selectedTaskIds.indexOf(leaf._id) !== -1}
            button
            onClick={(e) => {
                onClick(e);
                // don't initiate browser sharing when user opens the context menu
                if (['button', 'svg', 'path'].includes(e.target.localName)) {
                    return
                }
                browserShare({
                    currentLeaf: currentLeaf,
                    leaf: leaf
                });
            }
            }
            onContextMenu={onContextMenu}
            style={{
                ...provided.draggableProps.style,
                ...makeListItemStyle({
                    snapshot: snapshot,
                    leaf: leaf,
                    combineTarget: combineTarget,
                    selectedTaskIds: selectedTaskIds,
                    isLink: false
                })
            }}
        >
            <ListItemText style={makeStyle({
                dragInProgress: dragInProgress,
                snapshot: snapshot,
                leaf: leaf,
                selectedTaskIds: selectedTaskIds,
                shared: shared,
                currentLeaf: currentLeaf
            })} >
                <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
            </ListItemText>
            { /* need this fragment to make react-beautiful-dnd work correctly */}
            {!hideContextMenu &&
                <ContextMenuButton
                    leaf={leaf}
                    onClick={handleLeafContextClick}
                    snapshot={snapshot}
                    selectedTaskIds={selectedTaskIds}
                />
            }
        </ListItem>
    )
}