// material UI
import {
    ListItem,
    ListItemText,
    ListItemIcon,
    Link
} from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import { validImageUrl, validUrl } from '../util/url-checker';
// styles
import { makeListItemStyle } from '../styles/listItem';
// icons
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
// my components
import ListItemContent from './list-item/listItemContent';
import { browserShare } from '../util/browser-utils';

let doneStyle = {
    color: 'DarkSeaGreen',
    // textDecoration: 'line-through',
};

const ReadOnlyListItem = ({ leaf, path, currentLeaf, renderLinks }) => {

    let header;
    if (leaf) {
        header = Array.isArray(leaf.formats) && leaf.formats.indexOf('header') > -1;
    }

    return (
        <>
            {currentLeaf.type === 'checklist' ?
                <ListItem
                    style={makeListItemStyle({
                        leaf: leaf,
                        isLink: false
                    })}
                >
                    {!header &&
                        <>
                            {leaf.done ?
                                <ListItemIcon style={{ color: 'mediumseagreen' }}>
                                    <CheckCircle />
                                </ListItemIcon>
                                :
                                <ListItemIcon>
                                    <RadioButtonUnchecked />
                                </ListItemIcon>
                            }
                        </>
                    }
                    <ListItemText style={leaf.done ? doneStyle : null}>
                        <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
                    </ListItemText>
                </ListItem>
                :
                (leaf.linkTo === 'share') ?
                    <ListItem
                        button={leaf.locked ? false : true}
                        disabled={leaf.disabled}
                        onClick={() => browserShare({
                            currentLeaf: currentLeaf,
                            leaf: leaf
                        })}
                        style={makeListItemStyle({
                            leaf: leaf,
                            isLink: false
                        })}
                    >
                        <ListItemText style={leaf.done ? doneStyle : null}>
                            <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
                        </ListItemText>
                    </ListItem>
                    :
                    (!leaf.linkOnlyToLeaf && (leaf.linkto || validUrl(leaf.content))) ?
                        <ListItem
                            button={leaf.locked && validImageUrl(leaf.content) ? false : true}
                            disabled={leaf.disabled}
                            component={leaf.locked && validImageUrl(leaf.content) ? ListItem : Link}
                            target={leaf.sameTab ? "" : "_blank"}
                            rel="noopener noreferrer"
                            href={leaf.linkTo ?? leaf.content}
                            style={makeListItemStyle({
                                leaf: leaf,
                                isLink: true
                            })}
                        >
                            <ListItemText style={leaf.done ? doneStyle : null}>
                                <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
                            </ListItemText>
                        </ListItem>
                        :
                        renderLinks === true ?
                            <ListItem
                                button
                                component={path === null ? ListItem : NavLink}
                                to={path + leaf._id} // needs fix for deep shared leaf child links
                                style={makeListItemStyle({
                                    leaf: leaf,
                                    isLink: false
                                })}
                            >
                                <ListItemText style={leaf.done ? doneStyle : null}>
                                    <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
                                </ListItemText>
                            </ListItem>
                            :
                            <ListItem
                                style={makeListItemStyle({
                                    leaf: leaf,
                                    isLink: false
                                })}
                            >
                                <ListItemText style={leaf.done ? doneStyle : null}>
                                    <ListItemContent leaf={leaf} showChildCounts={!currentLeaf.hideChildCounts} />
                                </ListItemText>
                            </ListItem>
            }
        </>
    )
}

export default ReadOnlyListItem;