import {
    Link, Typography, Box
} from "@mui/material";
import { Link as NavLink } from 'react-router-dom';


const NotFound = ({setToken}) => {
    const logOut = () => {
        localStorage.removeItem('token', null);
        setToken(null);
    }

    return (
        <Box mt={4}>
            <Typography variant='h5' color="textPrimary" >
                Oops! Nothing to see here. 
                <Box mt={2}>
                    Head <Link component={NavLink} to="/" onClick={setToken ? logOut : null}>Home</Link>?
                </Box>
            </Typography>
        </Box>
    )
}

export default NotFound;