import { TextField } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import InputAdornment from '@mui/material/InputAdornment';
import { addLeaf, addSharedLeaf, addDeepSharedLeaf, uploadFile, uploadFileShared, uploadFileDeepShared } from '../util/leaf-functions';
import React, { useState } from 'react';
// icons
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';


const TextInput = ({ currentLeaf, leaves, setLeaves, token, linkShareId, useDeepAPI }) => {
    const [input, setInput] = useState("");

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleFileChange = (event) => {
        let file = event.clipboardData ? event.clipboardData.files[0] : event.target.files[0]
        if (!file) return; // Don't do anything when user pastes text
        let tempId = 'tempId:' + Math.random().toString();
        const newLeaf = {
            rank: Date.now(),
            content: "Uploading...",
            parentId: currentLeaf._id,
            formats: [],
            _id: tempId,
            disabled: true
        };
        setLeaves(leaves => {
            let newLeaves = [...leaves];
            newLeaves.push(newLeaf);
            return newLeaves;
        });
        let uploadFilePromise;
        if (linkShareId) {
            if (useDeepAPI) {
                uploadFilePromise = uploadFileDeepShared(file, newLeaf, linkShareId)
            } else {
                uploadFilePromise = uploadFileShared(file, newLeaf, linkShareId)
            }
        } else {
            uploadFilePromise = uploadFile(file, newLeaf, token)
        }
        uploadFilePromise
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    if (res.status === 400) {
                        alert('Oops! Files must be less than 100 Mb.')
                    }
                    if (res.status === 401) {
                        alert('You don\'t have permission to upload files here.')
                    }
                    return Promise.reject(res);
                }
            })
            .then(leaf => {
                // need to use most recent version of state when updating optimistically added leaves
                setLeaves(leaves => {
                    let newLeaves = [...leaves].filter(l => l._id !== tempId);
                    newLeaves.push(leaf);
                    return newLeaves;
                });
            })
            .catch((error) => {
                setLeaves(leaves => leaves.filter(l => l._id !== tempId));
            })
    }

    const handleSubmit = (event) => {
        if (event.key !== 'Enter' || !currentLeaf) {
            return;
        }
        let tempId = 'tempId:' + Math.random().toString();
        const newLeaf = {
            rank: Date.now(),
            content: input,
            parentId: currentLeaf._id,
            formats: [],
            _id: tempId,
            disabled: true
        };
        setLeaves(leaves => {
            let newLeaves = [...leaves];
            newLeaves.push(newLeaf);
            return newLeaves;
        });
        let leafCreationPromise;
        if (linkShareId) {
            if (useDeepAPI) {
                leafCreationPromise = addDeepSharedLeaf(newLeaf, linkShareId)
            } else {
                leafCreationPromise = addSharedLeaf(newLeaf, linkShareId)
            }
        } else {
            leafCreationPromise = addLeaf(newLeaf, token)
        }
        leafCreationPromise
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    return Promise.reject('request failed');
                }
            })
            .then(leaf => {
                // need to use most recent version of state when updating optimistically added leaves
                setLeaves(leaves => {
                    let newLeaves = [...leaves].filter(l => l._id !== tempId);
                    newLeaves.push(leaf);
                    return newLeaves;
                });
            })
            .catch((error) => {
                setLeaves(leaves => leaves.filter(l => l._id !== tempId));
                console.error('Error:', error);
            })
        setInput("")
        event.preventDefault();
    };

    return currentLeaf ?
        <>
            <TextField
                onPaste={handleFileChange}
                data-testid="leaf-text-input"
                id="filled-text-input"
                label="Jot something down.."
                autoComplete='off'
                value={input}
                variant="filled"
                multiline
                maxRows={6}
                fullWidth
                onChange={handleInputChange}
                onKeyPress={handleSubmit}
                InputProps={{
                    enterKeyHint: "send",
                    endAdornment:
                        <InputAdornment position="end" 
                            style={{marginBottom: '18px'}}
                        >
                            <label htmlFor="icon-button-file">
                                <IconButton aria-label="upload picture" component="span" size="large">
                                    <AddIcon />
                                </IconButton>
                            </label>
                            <input onChange={handleFileChange} encType='multipart/form-data' hidden accept="*" id="icon-button-file" name="file" type="file" />
                        </InputAdornment>
                }}
            >
            </TextField>
        </>
        :
        <Skeleton width="100%" height='80px'>
        </Skeleton>;
}

export default TextInput;