
import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from "react-router-dom";
// material UI
import { List, Box } from '@mui/material';
// my components
import BreadcrumbNav from "./breadcrumbNav";
import LeafSettingsMenu from "./leafSettingsMenu";
import DraggableListItem from "./list-item/draggableListItem";
import TextInput from "./TextInput";
import { isPwaInstalled, updatePwaAssets } from '../util/pwa-utils';
import InstallPrompt from './installPrompt';

const SecureShareLander = ({ setLinkshareId }) => {
    let { secureShareToken } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [appPreview, setAppPreview] = useState(false);

    useEffect(() => {
        if (!secureShareToken) {
            return alert('Invalid secureShare link.');
        }
        let linkshareId = localStorage.getItem('secure-link-local-key-' + secureShareToken);
        if (linkshareId) {
            setLinkshareId(linkshareId);
        } else {
            if (isPwaInstalled()) {
                consumeShareLink(secureShareToken)
            } else {
                getShareLink(secureShareToken)
            }
        }
    }, [])

    const getShareLink = (secureShareToken) => {

        fetch('/api/share/secure-share/' + secureShareToken, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(shareLinkPreview => {
                            setAppPreview(shareLinkPreview);
                            updatePwaAssets(shareLinkPreview);
                        })
                } else {
                    setRedirect('fail');
                    alert('Invalid secureShare link. Please request a new one.')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    const consumeShareLink = (secureShareToken) => {

        fetch('/api/share/secure-share/' + secureShareToken, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(secureShare => {
                            let shareLinkId = secureShare.shareLinkId;
                            let localStorageKey = 'secure-link-local-key-' + secureShareToken;
                            localStorage.setItem(localStorageKey, shareLinkId);
                            setLinkshareId(shareLinkId);
                        })
                } else {
                    setRedirect('fail');
                    alert('Invalid secureShare link. Please request a new one.')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    return (redirect === 'fail') ?
        <Redirect to={'/'} />
        :
        appPreview ?
            <InstallPrompt showInstallPrompt={true} setShowInstallPrompt={() => ({})} leaf={appPreview} ></InstallPrompt>
            :
            <>
                <BreadcrumbNav />
                <LeafSettingsMenu />
                <Box mt={2}>
                    <List>
                        <DraggableListItem />
                        <DraggableListItem />
                        <DraggableListItem />
                        <DraggableListItem />
                        <DraggableListItem />
                    </List>
                    <TextInput />
                </Box>
            </>
}

export default SecureShareLander;