import { useState } from 'react';
import {
    Box, Dialog, ToggleButton, ToggleButtonGroup, DialogActions, DialogContent, Button,
    DialogTitle, Checkbox, FormControlLabel, ListItem,
    MenuItem, TextField, Typography, InputLabel, FormControl, Select, InputAdornment
} from "@mui/material";
// icons
import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight } from "@mui/icons-material";

// reducers
import LeafReducer from '../reducers/leaf';

import { updateDeepSharedLeaf, updateLeaf, updateSharedLeaf } from '../util/leaf-functions';
import { validImageUrl, validUrl } from '../util/url-checker';

const getFontSizeSelectorValueFromString = (fontSize) => {
    if (fontSize === '0.75rem')
        return 'small'
    if (fontSize === undefined || fontSize === null)
        return 'medium'
    if (fontSize === '1.25rem')
        return 'large'
    return 'custom'
}

const EditLeafDialog = ({
    open, setLeaves, token,
    linkShareId, anchorLeaf, currentLeaf, useDeepAPI,
    handleClose
}) => {
    const [input, setInput] = useState(anchorLeaf.content);
    const [displayInput, setDisplayInput] = useState(anchorLeaf.displayAs);
    const [linkToInput, setLinkToInput] = useState(anchorLeaf.linkTo);
    const [linkOnlyToLeaf, setLinkOnlyToLeaf] = useState(anchorLeaf.linkOnlyToLeaf);
    const [fontSizeSelector, setFontSizeSelector] = useState(getFontSizeSelectorValueFromString(anchorLeaf.fontSize));
    const [stylePanelOpen, setStylePanelOpen] = useState(false);
    const [fontSizeInput, setFontSizeInput] = useState(anchorLeaf.fontSize?.replace('rem', ''));
    const [fontFamily, setFontFamily] = useState(anchorLeaf.fontFamily || 'Roboto');
    const [textColor, setTextColor] = useState(anchorLeaf.textColor || '');
    const [borderRadius, setBorderRadius] = useState(anchorLeaf.borderRadius?.replace('px', ''));
    const [background, setBackground] = useState(anchorLeaf.background || '');
    const [margin, setMargin] = useState(anchorLeaf.margin || '');
    const [textAlign, setTextAlign] = useState(anchorLeaf.textAlign || 'left');
    const [appName, setAppName] = useState(anchorLeaf.appName);
    const [homeScreenIconUrl, setHomescreenIconUrl] = useState(anchorLeaf.appleTouchIconUrl);
    const [faviconUrl, setFaviconUrl] = useState(anchorLeaf.faviconUrl);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && currentLeaf) {
            handleSubmit(event);
        }
    }

    const handleInputChange = (event) => {
        setInput(event.target.value);
        event.preventDefault();
    };

    const handleDisplayInputChange = (event) => {
        setDisplayInput(event.target.value);
        event.preventDefault();
    };

    const handleLinkToInputChange = (event) => {
        setLinkToInput(event.target.value);
        event.preventDefault();
    };

    const handleLinkOnlyToLeafChange = (event) => {
        setLinkOnlyToLeaf(event.target.checked);
        event.preventDefault();
    };

    const handleFontSizeSelectorChange = (event, selected) => {
        if (selected != null)
            setFontSizeSelector(selected);
    }

    const handleFontSizeInputChange = (event) => {
        if (event.target.validity.valid)
            setFontSizeInput(event.target.value)
        event.preventDefault();
    }

    const handleTextAlignSelectorChange = (event, selected) => {
        if (selected !== null) {
            setTextAlign(selected);
        }
        event.preventDefault();
    };

    const handleTextColorInputChange = (event) => {
        setTextColor(event.target.value);
        event.preventDefault();
    };

    const handleBorderRadiusInputChange = (event) => {
        if (event.target.validity.valid)
            setBorderRadius(event.target.value);
        event.preventDefault();
    };

    const handleBackgroundInputChange = (event) => {
        setBackground(event.target.value);
        event.preventDefault();
    };

    const handleMarginInputChange = (event) => {
        setMargin(event.target.value);
        event.preventDefault();
    };

    const handleAppNameInputChange = (event) => {
        setAppName(event.target.value);
        event.preventDefault();
    };

    const handleHomescreenIconUrlInputChange = (event) => {
        setHomescreenIconUrl(event.target.value);
        event.preventDefault();
    };

    const handleFaviconUrlInputChange = (event) => {
        setFaviconUrl(event.target.value);
        event.preventDefault();
    };


    const getFontSizeFromSelections = () => {
        if (fontSizeSelector === 'small')
            return '0.75rem'
        if (fontSizeSelector === 'medium')
            return null;
        if (fontSizeSelector === 'large')
            return '1.25rem'
        if (fontSizeSelector === 'custom' && fontSizeInput)
            return fontSizeInput.replace(/ /g, '') + 'rem'; // fontSizeInput will go here
        return null
    }

    const handleSubmit = (event) => {
        let updatedLeaf = {
            ...anchorLeaf,
            content: input,
            disabled: true
        };

        updatedLeaf.displayAs = displayInput;
        if (updatedLeaf.displayAs === '')
            updatedLeaf.displayAs = null;

        updatedLeaf.linkTo = linkToInput;
        if (updatedLeaf.linkTo === '')
            updatedLeaf.linkTo = null;

        updatedLeaf.linkOnlyToLeaf = linkOnlyToLeaf ? true : null;

        updatedLeaf.textAlign = textAlign;
        if (updatedLeaf.textAlign === 'left')
            updatedLeaf.textAlign = null;

        updatedLeaf.fontSize = getFontSizeFromSelections();
        updatedLeaf.fontFamily = (fontFamily !== 'Roboto') ? fontFamily : null

        updatedLeaf.textColor = textColor;
        if (updatedLeaf.textColor === '')
            updatedLeaf.textColor = null;

        if (!borderRadius)
            updatedLeaf.borderRadius = null;
        else
            updatedLeaf.borderRadius = `${borderRadius}px`;

        updatedLeaf.background = background;
        if (updatedLeaf.background === '')
            updatedLeaf.background = null;

        updatedLeaf.margin = margin;
        if (updatedLeaf.margin === '')
            updatedLeaf.margin = null;

        updatedLeaf.appName = appName;
        if (updatedLeaf.appName === '')
            updatedLeaf.appName = null;

        updatedLeaf.appleTouchIconUrl = homeScreenIconUrl;
        if (updatedLeaf.appleTouchIconUrl === '')
            updatedLeaf.appleTouchIconUrl = null;

        updatedLeaf.faviconUrl = faviconUrl;
        if (updatedLeaf.faviconUrl === '')
            updatedLeaf.faviconUrl = null;

        setLeaves(leaves => LeafReducer.updateLeaf(leaves, updatedLeaf));

        let leafUpdatePromise;
        if (linkShareId) {
            if (useDeepAPI) {
                leafUpdatePromise = updateDeepSharedLeaf(
                    updatedLeaf._id,
                    updatedLeaf,
                    linkShareId
                )
            } else {
                leafUpdatePromise = updateSharedLeaf(
                    updatedLeaf._id,
                    updatedLeaf,
                    linkShareId
                )
            }
        } else {
            leafUpdatePromise = updateLeaf(
                updatedLeaf._id,
                updatedLeaf,
                token
            )
        }
        leafUpdatePromise
            .then(response => {
                if (response.status === 200) {
                    // need to use most recent version of state when updating optimistically added leaves
                    updatedLeaf.disabled = false;
                    setLeaves(leaves => LeafReducer.updateLeaf(leaves, updatedLeaf));
                } else {
                    throw new Error("something went wrong with this request")
                }
            })
            .catch((error) => {
                // this needs to be fixed to just undo the content update, not remove the leaf
                setLeaves(leaves => {
                    let newLeaves = leaves.filter(l => l._id !== updatedLeaf._id);
                    newLeaves.push(anchorLeaf);
                    return newLeaves;
                });
                console.error('Error:', error);
            })
        setInput("")
        handleClose();
        event.preventDefault();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth maxWidth="sm"
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'baseline'
                }
            }}
        >
            <DialogTitle id="form-dialog-title">Edit leaf</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={!anchorLeaf.displayAs && !validUrl(anchorLeaf.content)}
                    fullWidth
                    value={input}
                    label="Jot something down.."
                    inputProps={{ enterKeyHint: "done" }}
                    variant="filled"
                    multiline
                    maxRows={6}
                    data-testid="edit-leaf-text-input"
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onFocus={(event) => setTimeout(() => { event.target.selectionStart = event.target.selectionEnd = 10000; }, 0)} // set cursor to end of content
                />
                {anchorLeaf && (anchorLeaf.displayAs || validUrl(anchorLeaf.content)) &&
                    <Box mt={2}>
                        <TextField
                            size='small'
                            autoFocus={!validImageUrl(anchorLeaf.content)}
                            fullWidth
                            value={displayInput || ''}
                            label="Display as"
                            inputProps={{ enterKeyHint: "done" }}
                            variant="filled"
                            multiline
                            maxRows={6}
                            data-testid="edit-leaf-text-display-input"
                            onChange={handleDisplayInputChange}
                            onKeyPress={handleKeyPress}
                            onFocus={(event) => setTimeout(() => { event.target.selectionStart = event.target.selectionEnd = 10000; }, 0)} // set cursor to end of content
                        />
                    </Box>
                }
                {!stylePanelOpen &&
                    <Box mt={2}>
                        <Button
                            onClick={e => setStylePanelOpen(true)}
                        >
                            Show more
                        </Button>
                    </Box>
                }
                {stylePanelOpen &&
                    <Box>
                        <Box mt={2}>
                            <ListItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size='small'
                                            data-testid="edit-leaf-link-only-to-leaf-switch"
                                            checked={Boolean(linkOnlyToLeaf)}
                                            onChange={handleLinkOnlyToLeafChange}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="body2">Click opens leaf instead of link</Typography>}
                                >
                                </FormControlLabel>
                            </ListItem>
                            <TextField
                                size='small'
                                fullWidth
                                value={linkToInput || ''}
                                label="Link to"
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                multiline
                                maxRows={6}
                                disabled={linkOnlyToLeaf}
                                data-testid="edit-leaf-link-to-input"
                                onChange={handleLinkToInputChange}
                                onKeyPress={handleKeyPress}
                                onFocus={(event) => setTimeout(() => { event.target.selectionStart = event.target.selectionEnd = 10000; }, 0)} // set cursor to end of content
                            />
                        </Box>
                        <Box mt={2}>
                            <Typography>Font size</Typography>
                            <ToggleButtonGroup
                                size='small'
                                exclusive
                                value={fontSizeSelector}
                                onChange={handleFontSizeSelectorChange}
                                aria-label="font size"
                            >
                                <ToggleButton value="small" style={{ fontSize: '0.75rem' }} aria-label="small">
                                    small
                                </ToggleButton>
                                <ToggleButton value="medium" style={{ fontSize: '1rem' }} aria-label="medium">
                                    medium
                                </ToggleButton>
                                <ToggleButton value="large" style={{ fontSize: '1.25rem' }} aria-label="large">
                                    large
                                </ToggleButton>
                                <ToggleButton value="custom" aria-label="custom" disableRipple>
                                    {/* custom
                            {fontSizeSelector === 'custom' && ': '} */}
                                    {fontSizeSelector === 'custom' ?
                                        <TextField
                                            size='small'
                                            autoFocus
                                            value={fontSizeInput || ''}
                                            placeholder='1.5'
                                            inputProps={{
                                                enterKeyHint: "done",
                                                inputMode: 'decimal',
                                                pattern: '([0-9]+)?[,\\.]?[0-9]*'
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">rem</InputAdornment>,
                                            }}
                                            variant="standard"
                                            data-testid="edit-leaf-font-size-input"
                                            onChange={handleFontSizeInputChange}
                                            onKeyPress={handleKeyPress}
                                            style={{ textTransform: 'none', maxWidth: '80px' }}
                                            onFocus={event => {
                                                event.preventDefault();
                                                event.target.select();
                                            }}
                                        />
                                        :
                                        'custom'
                                    }
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box mt={2}>
                            <Typography>Text alignment</Typography>
                            <ToggleButtonGroup
                                // size='small'
                                exclusive
                                value={textAlign}
                                onChange={handleTextAlignSelectorChange}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="left" aria-label="left aligned">
                                    <FormatAlignLeft />
                                </ToggleButton>
                                <ToggleButton value="center" aria-label="centered">
                                    <FormatAlignCenter />
                                </ToggleButton>
                                <ToggleButton value="right" aria-label="right aligned">
                                    <FormatAlignRight />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box mt={2}>
                            <FormControl variant="filled">
                                <InputLabel id="demo-simple-select-label">Font family</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fontFamily}
                                    label="Font family"
                                    onChange={e => setFontFamily(e.target.value)}
                                    style={{ fontFamily: fontFamily, minWidth: '200px' }}
                                >
                                    <MenuItem value={'Roboto'}>Roboto</MenuItem>
                                    <MenuItem style={{ fontFamily: 'Patua One' }} value={'Patua One'}>Patua</MenuItem>
                                    <MenuItem style={{ fontFamily: 'Cormorant SC' }} value={'Cormorant SC'}>Cormorant</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                value={textColor || ''}
                                label="Font color"
                                placeholder='maroon'
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-text-color-input"
                                style={{ minWidth: '200px' }}
                                onChange={handleTextColorInputChange}
                                onKeyPress={handleKeyPress}
                                onFocus={event => {
                                    event.preventDefault();
                                    event.target.select();
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                value={borderRadius || ''}
                                label="Border radius"
                                placeholder='6'
                                inputProps={{
                                    enterKeyHint: "done",
                                    inputMode: 'number',
                                    pattern: '[0-9]*'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">px</InputAdornment>
                                }}
                                variant="filled"
                                data-testid="edit-leaf-text-border-radius-input"
                                style={{ width: '200px' }}
                                onChange={handleBorderRadiusInputChange}
                                onKeyPress={handleKeyPress}
                                onFocus={event => {
                                    event.preventDefault();
                                    event.target.select();
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                value={background || ''}
                                label="Background"
                                placeholder='lightblue'
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-background-input"
                                style={{ minWidth: '200px' }}
                                onChange={handleBackgroundInputChange}
                                onKeyPress={handleKeyPress}
                                onFocus={event => {
                                    event.preventDefault();
                                    event.target.select();
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                value={margin || ''}
                                label="Margin"
                                placeholder='10px 0px'
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-margin-input"
                                style={{ minWidth: '200px' }}
                                onChange={handleMarginInputChange}
                                onKeyPress={handleKeyPress}
                                onFocus={event => {
                                    event.preventDefault();
                                    event.target.select();
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                fullWidth
                                value={appName || ''}
                                label="App Name"
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-app-name-input"
                                onChange={handleAppNameInputChange}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                size='small'
                                fullWidth
                                value={homeScreenIconUrl || ''}
                                label="Homescreen Icon Url"
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-homescreen-icon-input"
                                onChange={handleHomescreenIconUrlInputChange}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>
                        {homeScreenIconUrl &&
                            <Box mt={2}>
                                <img width="60px" src={homeScreenIconUrl} />
                            </Box>
                        }
                        <Box mt={2}>
                            <TextField
                                size='small'
                                fullWidth
                                value={faviconUrl || ''}
                                label="Favicon Url"
                                inputProps={{ enterKeyHint: "done" }}
                                variant="filled"
                                data-testid="edit-leaf-favicon-input"
                                onChange={handleFaviconUrlInputChange}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>
                        {faviconUrl &&
                            <Box mt={2}>
                                <img width="20px" src={faviconUrl} />
                            </Box>
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" data-testid='edit-leaf-dialog-save-button'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditLeafDialog;