var noOverflow = {
    overflow: 'hidden',
    textOverflow: 'ellipsis !important',
}

var wrapOverflow = {
    overflowWrap: 'anywhere'
}

var respectWhitespace = {
    whiteSpace: 'pre-wrap'
}

export { noOverflow, wrapOverflow, respectWhitespace };