// material UI
import {
    List,
    Box
} from "@mui/material";

import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import confetti from 'canvas-confetti';
// util
import { updateSharedLeaf, updateDeepSharedLeaf } from '../util/leaf-functions';
import { sortLeaves } from "../util/sort-leaves";
// my components
import DraggableListItem from "./list-item/draggableListItem";
import UndoSnackbar from "./undoSnackbar";
import LeafSettingsMenu from "./leafSettingsMenu";
import ListItemContextMenu from "./listItemContextMenu";
import TextInput from "./TextInput";

const EditableLeaf = ({
    dragInProgress, leaves, setLeaves, currentLeaf, setCurrentLeaf, combineTarget,
    linkShareId, token, setToken, linkShare, setLinkShare, path, deepShared, renderLinks
}) => {
    // anchors for context menus
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLeaf, setAnchorLeaf] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    // const [leafPendingDeletion, setLeafPendingDeletion] = React.useState(null);
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const handleLeafContextClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        if (event.currentTarget.value)
            setAnchorLeaf(JSON.parse(event.currentTarget.value));
        setContextMenuOpen(true);
    };

    const handleRightClick = (event, leaf) => {
        event.preventDefault();
        setAnchorLeaf(JSON.parse(leaf));
        setContextMenuPosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setContextMenuOpen(true);
    };

    const toggleMarkDone = (e, leaf) => {
        if (leaf) {
            if (!leaf.done) {
                confetti({
                    startVelocity: 13,
                    origin: {
                        x: e.clientX / window.innerWidth,
                        y: e.clientY / window.innerHeight - (contextMenuOpen ? 0.07 : 0)
                    },
                    spread: 300,
                    ticks: 80,
                    zIndex: 1000
                });
            }
            let leafCopy = leaf;
            leafCopy.done = !leafCopy.done;
            let newLeaves = [...leaves];
            let index = newLeaves.findIndex(l => l._id === leafCopy._id);
            newLeaves[index] = leafCopy;
            setLeaves(newLeaves); // optimistic update
            let updatePromise = deepShared ?
                updateDeepSharedLeaf(leafCopy._id, leafCopy, linkShareId) :
                updateSharedLeaf(leafCopy._id, leafCopy, linkShareId)
            updatePromise
                .then(response => {
                    if (!response.status || response.status !== 200) {
                        console.error("updating a leaf failed with non OK response");
                        throw new Error("Something went wrong with request to mark leaf as done");
                    }
                })
                .catch(err => {
                    // roll back optimistic update
                    leafCopy.done = !leafCopy.done;
                    let newLeaves = [...leaves];
                    newLeaves[index] = leafCopy;
                    setLeaves(newLeaves);
                });
        }
    }

    return (
        <>
            {!currentLeaf || !leaves ?
                <> {/* show nothing for now for loading */}
                </>
                // <Typography color="textPrimary" >
                //     <br/><br/> 🌱 Loading...
                // </Typography>
                :
                <>
                    <LeafSettingsMenu
                        currentLeaf={currentLeaf}
                        setCurrentLeaf={setCurrentLeaf}
                        token={token}
                        setToken={setToken}
                        linkShareId={linkShareId}
                        linkShare={linkShare}
                        setLinkShare={setLinkShare}
                        deepShared={deepShared}
                    />
                    {typeof leaves === 'object' && leaves !== null &&
                        <Droppable droppableId="leaf-list" isCombineEnabled={currentLeaf.combine === undefined ? currentLeaf.shareDeep : currentLeaf.shareDeep && Boolean(currentLeaf.combine)}>
                            {// render children of current post
                                (provided) => (
                                    <List
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        dense={currentLeaf && currentLeaf.dense}
                                    >
                                        <Box mt={2}>
                                            {leaves
                                                // .filter(leaf => leaf.parentId === id)
                                                .sort(sortLeaves)
                                                .map((leaf, index) => (
                                                    <DraggableListItem
                                                        leaf={leaf}
                                                        shared={true}
                                                        index={index}
                                                        path={path}
                                                        handleLeafContextClick={handleLeafContextClick}
                                                        handleRightClick={handleRightClick}
                                                        key={leaf._id}
                                                        dragInProgress={dragInProgress}
                                                        toggleMarkDone={toggleMarkDone}
                                                        currentLeaf={currentLeaf}
                                                        renderLinks={renderLinks}
                                                        combineTarget={combineTarget}
                                                    />
                                                ))}
                                            {provided.placeholder}
                                        </Box>
                                    </List>
                                )}
                        </Droppable>}
                    {anchorLeaf &&
                        <ListItemContextMenu
                            contextMenuOpen={contextMenuOpen}
                            setContextMenuOpen={setContextMenuOpen}
                            linkShareId={linkShareId}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            anchorLeaf={anchorLeaf}
                            setAnchorLeaf={setAnchorLeaf}
                            contextMenuPosition={contextMenuPosition}
                            setContextMenuPosition={setContextMenuPosition}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            currentLeaf={currentLeaf}
                            toggleMarkDone={toggleMarkDone}
                            deepShared={deepShared}
                            renderLinks={renderLinks}
                            path={path}
                            useDeepAPI={deepShared}
                        />
                    }
                    {(!leaves.length || !leaves.every(leaf => leaf.locked === true)) &&
                        <TextInput
                            currentLeaf={currentLeaf}
                            leaves={leaves}
                            setLeaves={setLeaves}
                            linkShareId={linkShareId}
                            useDeepAPI={deepShared}
                        />
                    }
                    <Box mt={4}>
                        <UndoSnackbar
                            undo={() => {
                                // addLeaf(leafPendingDeletion);
                                // setLeafPendingDeletion(null);
                            }}
                            open={snackBarOpen}
                            setOpen={setSnackBarOpen} />
                    </Box>
                </>
            }
        </>
    );
}

export default EditableLeaf;