import React from 'react';
// material UI
import { Dialog, DialogContent, DialogTitle, DialogContentText, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// util
import { isMobile, isSafari } from '../util/browser-utils';

const InstallPrompt = ({ showInstallPrompt, setShowInstallPrompt, leaf }) => {
    let theme = useTheme();
    console.log(theme.palette.mode);
    let darkMode = theme.palette.mode === 'dark'

    const handleClose = () => {
        setShowInstallPrompt(false)
    }

    const appIconPreviewStyles = {
        borderRadius: isSafari ? '22.5%' : 0,
        border: '1px solid rgba(0, 0, 0, 0.125)',
        marginRight: '10px'
    }

    const appInfoPreviewStyles = {
        marginBottom: '20px',
        // color: 'black'
    }

    return (
        <Dialog
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'baseline'
                }
            }}
            open={showInstallPrompt && isMobile()}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Install the App"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <List>
                        {leaf &&
                            <ListItem style={appInfoPreviewStyles}>
                                <img width="60px" style={appIconPreviewStyles} src={leaf.appleTouchIconUrl ? leaf.appleTouchIconUrl : "/apple-touch-icon.png"} alt="homescreen icon of a leaf" /> {leaf.appName || document.title}
                            </ListItem>
                        }
                        <ListItem>1.  Tap <img width="34px" src={isSafari ? darkMode ? "/img/ios-share-icon-dark-mode.png" : "/img/ios-share-icon.png" : "/img/3dots-icon.png"} alt={isSafari ? "share icon" : "more options icon"} /> in the browser tab bar</ListItem>
                        <ListItem>2.  {isSafari ? "Swipe up" : "Scroll"} for more options</ListItem>
                        <ListItem>3.  Tap <img width="180px" src={isSafari ? darkMode ? "/img/add-to-home-screen-dark-mode.png" : "/img/add-to-home-screen.png" : "/img/add-to-home-screen-android.png"} alt={isSafari ? "add to home screen button" : "add to home screen menu item"} /></ListItem>
                    </List>
                </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Done
                </Button>
            </DialogActions> */}
        </Dialog>
    )
}


export default InstallPrompt;