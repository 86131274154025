import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "@fontsource/roboto"
import "@fontsource/patua-one"
import "@fontsource/cormorant-sc"
import "@fontsource/cousine"

import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

function ThemedApp() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [backgroundColor, setBackgroundColor] = useState(null);
  // const [input, setInput] = useState(anchorLeaf.content);

  const theme = React.useMemo(
    () => {
      let palette = {
        mode: prefersDarkMode ? 'dark' : 'light',
      }
      if(backgroundColor)
        palette.background = { default: backgroundColor };

      return createTheme({
        // typography: {
        //   fontFamily: 'Patua One, Roboto, sans-serif',
        // },
        palette: palette,
        components: {
          MuiTextField: {
            defaultProps: {
              variant: 'filled',
            },
          },
          MuiLink: {
            defaultProps: {
              underline: 'hover',
            },
          },
        }
      })
    },
    [prefersDarkMode, backgroundColor],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App setBackgroundColor={setBackgroundColor} />
    </ThemeProvider>
  );
}


ReactDOM.render(
  <React.StrictMode>
    <ThemedApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
